import React, { useState, useEffect } from 'react'
import { Container, Button, Nav, Tab, Row, Col, Card, ListGroup, Modal } from 'react-bootstrap'
import ButtonLoading from '../../components/ButtonLoading'
import getProducerInfo from '../../services/getProducerInfo'
import getProducerSubscribers from '../../services/getProducerSubscribers'
import getAffiliates from '../../services/getAffiliates'
import { useHistory } from 'react-router-dom'
import { currencyFormat, time_ago } from '../../services/utils'
import { LoadingItens } from '../../components/Loadings'
import { kondutoPage, kondutoCustomerId } from '../../services/konduto'
import './style.scss'

function Billing () {
  const [affiliates, setAffiliates] = useState([])
  const [subscribers, setSubscribers] = useState(false)
  const [producerInfo, setProducerInfo] = useState(false)
  const [btnloading, setBtnloading] = useState(false)
  const [isBtnDisable, setIsBtnDisable] = useState(true)
  const [infoModal, setInfoModal] = useState({ status: false, title: '', msg: '' })
  const history = useHistory()

  useEffect(() => {
    if (producerInfo.balance_available >= 500 && producerInfo.has_pending_withdrawal === false) {
      setIsBtnDisable(false)
    } else {
      setIsBtnDisable(true)
      // setIsBtnDisable(false)
    }
  }, [producerInfo])

  useEffect(() => {
    getProducerSubscribers(setSubscribers)
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    getProducerInfo(user.nickname, setProducerInfo)
    getAffiliates(setAffiliates)
    kondutoPage('billing')
    kondutoCustomerId(user.vindi_customer)
    // console.log('Produtor Subs:', subscribers)
    // console.log('Produtor info:', producerInfo)
  }, [])

  function handlePaymentProducer () {
    setBtnloading(true)
    history.push({ pathname: '/producer-payment', state: { valueProps: producerInfo.balance_available } })
    setBtnloading(false)
  }

  function handlerInfoModal (event) {
    let msg = ''
    let title = ''
    switch (event) {
      case 'chargeback':
        title = 'Pedidos de chargeback'
        msg = 'Chargebacks são transações não reconhecidas pelo titular do cartão de crédito, cujas transações foram glosadas na fonte pela adquirente.'
        setInfoModal({ status: true, title: title, msg: msg })
        break
      case 'comissao':
        title = 'Comissão assinantes de outras plataformas'
        msg = 'Neste momento você possui assinaturas que foram importadas de outras plataformas e este valor é referente à comissão da TokPlace pelo uso deste serviço.'
        setInfoModal({ status: true, title: title, msg: msg })
        break
      case 'afiliado':
        title = 'Comissão de afiliados'
        msg = 'Este é o valor total da comissão recebida pelas vendas realizadas pelos seus produtores afiliados.'
        setInfoModal({ status: true, title: title, msg: msg })
        break
      default:
        setInfoModal({ status: false, title: title, msg: msg })
        break
    }
  }

  const MenuTab = () => {
    return (
      <Nav className="btn-group mb-3 text-dark">
        <Nav.Link eventKey="faturamento" className='btn btn-dark py-4'>Faturamento</Nav.Link>
        <Nav.Link eventKey="afiliados" className='btn btn-dark py-4'>Afiliados</Nav.Link>
        <Nav.Link eventKey="assinantes" className='btn btn-dark py-4'>Assinantes</Nav.Link>
      </Nav>
    )
  }
  const Assinantes = () => {
    return (
      <Tab.Pane eventKey="assinantes">
        {subscribers.length > 0 && <h5 className='font-weight-light'>Assinantes</h5>}
        <ListGroup variant="flush">
          {subscribers.map(function (subscription) {
            return (
              <ListGroup.Item key={subscription.id} className={'d-flex justify-content-between px-0 font-weight-light'}>
                <div>{subscription.consumer.name}</div>
                <div>{subscription.activated_at && time_ago(subscription.activated_at)}</div>
              </ListGroup.Item>
            )
          })}
        </ListGroup>
      </Tab.Pane>
    )
  }
  const Faturamento = () => {
    return (
      <Tab.Pane eventKey="faturamento">
        <ListGroup variant="flush" className={'font-weight-light'}>
          <ListGroup.Item className={'px-0'}>
            <Row>
              <Col xs={12} className='text-center'><h3><small>Seu saldo total atual é</small><br/>{currencyFormat(producerInfo.balance_full)}</h3></Col>
              <Col xs={6} className='font-weight-bold'>Saldo a liberar</Col>
              <Col xs={6} className={'text-right font-weight-bold'}>{currencyFormat(producerInfo.balance_scheduled)}</Col>
              <Col xs={12}>
                <p className={'mb-0'}>
                  <small>
                    Os pagamentos recebidos são liberados para retirada após os seguinte prazos:<br/>
                  </small>
                </p>
                <ul className='pl-3'>
                  <li><small>Pagamentos por cartão: 30 dias após a compra de cada assinatura.</small></li>
                  <li><small>Pagamentos por PIX: 7 dias após a compra de cada assinatura.</small></li>
                </ul>
              </Col>
              <Col xs={6} className='font-weight-bold'>Saldo liberado</Col>
              <Col xs={6} className={'text-right font-weight-bold'}>{currencyFormat(producerInfo.balance_released)}</Col>
              <Col xs={12}>
                <p className={'mb-3'}>
                  <small>Valor que completou o prazo e que já pode ser acumulado para solicitação de retirada.</small>
                </p>
              </Col>
              <Col xs={12}>
                <ul className='pl-3'>
                  <li className='row mb-3'>
                    <small className='col-7 font-weight-bold text-nowrap text-break' style={{ zIndex: 100 }}>
                      (-) Pedidos de chargeback:
                      <Button className="ml-1 btn btn-primary py-2 px-1" onClick={() => handlerInfoModal('chargeback')}>?</Button>
                    </small>
                    <small className='col-5 text-right font-weight-bold text-break'>- {currencyFormat(producerInfo.amount_chargeback)}</small>
                  </li>
                  <li className='row mb-3'>
                    <small className='col-7 font-weight-bold' style={{ zIndex: 100 }}>
                      (-) Comissão assinantes de outras plataformas:
                      <Button className="ml-1 btn btn-primary py-2 px-1" onClick={() => handlerInfoModal('comissao')}>?</Button>
                    </small>
                    <small className='col-5 my-auto text-right font-weight-bold'>- {currencyFormat(producerInfo.tokplace_commission)}</small>
                  </li>
                  <li className='row mb-3'>
                    <small className='col-7 font-weight-bold text-nowrap text-break' style={{ zIndex: 100 }}>
                      (+) Comissão de afiliados:
                      <Button className="ml-1 btn btn-primary py-2 px-1" onClick={() => handlerInfoModal('afiliado')}>?</Button>
                    </small>
                    <small className='col-5 text-right font-weight-bold text-nowrap text-break'>+ {currencyFormat(producerInfo.affiliate_commission)}</small>
                  </li>
                </ul>
                <Modal show={infoModal.status} onHide={() => handlerInfoModal('close')} className='d-flex infoModal'>
                  <Modal.Header closeButton><p className='lead font-weight-bold mb-0'>{infoModal.title}</p></Modal.Header>
                  <Modal.Body>{infoModal.msg}</Modal.Body>
                </Modal>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item className={'px-0'}>
            <Row>
              <Col xs={7} className='font-weight-bold'><strong>(=) Disponível para retirada</strong></Col>
              <Col xs={5} className={'text-right my-auto font-weight-bold'}>{currencyFormat(producerInfo.balance_available ? producerInfo.balance_available : 0)}</Col>
              <Col xs={12}>
                <p className={''}>
                  <small>Valor que completou o prazo e que já pode ser acumulado para solicitação de retirada, já descontada a comissão pelos assinantes importados de outras plataformas (caso possua usuários importados).</small>
                </p>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
        <div className='form m-0'>
          <Button type={'button'} className={'w-100'} variant="submit" onClick={() => { handlePaymentProducer() }} disabled={isBtnDisable}>
            <ButtonLoading label={ producerInfo.has_pending_withdrawal ? 'Retirada pendente' : 'Solicitar retirada' } active={btnloading} />
          </Button>
        </div>
        <p className={'font-weight-light text-center'}><small>Você pode efetuar retiradas de, no mínimo,<br /> R$ 500,00 por transação.</small></p>
      </Tab.Pane>
    )
  }
  const Afiliados = () => {
    return (
      <Tab.Pane eventKey="afiliados">
        <h5 className='font-weight-light'>Afiliados</h5>
        <ListGroup variant="flush">
          {affiliates.map((item, index) => {
            console.log(item)
            return (
              <ListGroup.Item key={`affiliates-${index}`} className={'d-flex justify-content-between px-0 font-weight-light'}>
                <div>{item.affiliate.name}</div>
                <div>{time_ago(item.created_at)}</div>
              </ListGroup.Item>
            )
          })}
        </ListGroup>
      </Tab.Pane>
    )
  }

  return (
    <section>
      <Container className='content Billing'>
        <Card className={'col-md-7 col-lg-5 mx-auto pt-3'}>
          <Card.Body className='p-0'>
            <Tab.Container defaultActiveKey="faturamento">
              <MenuTab />
              <Tab.Content>
                {(subscribers && producerInfo) && <Faturamento />}
                {(subscribers && producerInfo) && <Assinantes />}
                <Afiliados />
                {(!subscribers && !producerInfo) && <LoadingItens className={'justify-content-center mb-4'} label='carregando...' />}
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </Container>
    </section>
  )
}

export default Billing
