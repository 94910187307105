import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { getSidebarItems } from './sidebar.jsx'
import { IconContext } from 'react-icons'
import { MdExitToApp, MdAddAPhoto, MdClose, MdDelete, MdMoreVert } from 'react-icons/md'
import { BsGear, BsSearch, BsHouse, BsPlusSquare } from 'react-icons/bs'
import { AiOutlineUser, AiTwotoneRightSquare } from 'react-icons/ai'
import { LogoInline } from '../Logo'
import { Nav, Navbar, Container } from 'react-bootstrap'
// import Toast from '../toast/Toast'
import isAuthenticated from '../../services/IsAuthenticated.js'
import api from '../../services/api'
import './style.scss'

const NavbarTop = (props) => {
  const history = useHistory()
  const [isVisibleSidebar, setIsVisibleSidebar] = useState(false)
  const [sidebarItems, setSidebarItems] = useState([])
  const visible = props.Menu !== undefined ? props.Menu : true

  const handleSidebar = () => {
    function checkSidebarItems () {
      const sidebarItems = getSidebarItems()
      setSidebarItems(sidebarItems)
    }
    checkSidebarItems()
    window.addEventListener('storage', checkSidebarItems)
    return () => {
      window.removeEventListener('storage', checkSidebarItems)
    }
  }

  useEffect(() => {
    handleSidebar()
  }, [])

  const showSidebar = () => setIsVisibleSidebar(!isVisibleSidebar)
  function Logout () {
    sessionStorage.removeItem('token')
    localStorage.removeItem('name')
    localStorage.removeItem('email')
    localStorage.removeItem('user')
    history.push('/login')
  }

  const ListaLinksMenu = () => {
    return (
      <Nav className="ml-auto">
        { sidebarItems.map((item, index) => {
          return (
            <Nav.Link key={index} className={item.cName} href={item.path}>
              <span className='mr-2'>{item.icon}</span>
              <span className='nav-item'>{item.title}</span>
            </Nav.Link>
          )
        })}
        {isAuthenticated() &&
          <Nav.Link href="/login" onClick={Logout}>
            <MdExitToApp className="mr-2"/>
            <span className='nav-item'>Sair</span>
          </Nav.Link>
        }
      </Nav>
    )
  }

  return (
    visible &&
    <Navbar collapseOnSelect expand="lg" className={'NavbarTop bg-gradiente-primary shadow'} variant="dark" fixed="top">
      <Container>
        <Navbar.Brand href="/">
          <LogoInline className="brand d-inline-block align-top"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <ListaLinksMenu />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavbarTop
