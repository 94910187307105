import React from 'react'
import { Container } from 'react-bootstrap'
import '../../styles/forms.scss'
import './style.scss'
import img01 from '../../assets/manual/img01.png'
import img02 from '../../assets/manual/img02.png'
import img03 from '../../assets/manual/img03.png'
import img04 from '../../assets/manual/img04.png'
import img05 from '../../assets/manual/img05.png'
import img06 from '../../assets/manual/img06.png'
import img07 from '../../assets/manual/img07.png'
import img08 from '../../assets/manual/img08.png'
import img09 from '../../assets/manual/img09.png'
import img10 from '../../assets/manual/img10.png'
import img11 from '../../assets/manual/img11.png'
import img12 from '../../assets/manual/img12.png'
import img13 from '../../assets/manual/img13.png'
import img14 from '../../assets/manual/img14.png'
import img15 from '../../assets/manual/img15.png'
import img16 from '../../assets/manual/img16.png'
import img17 from '../../assets/manual/img17.png'
import img18 from '../../assets/manual/img18.png'
import img19 from '../../assets/manual/img19.jpeg'

function Manual () {
  const Bullets = (props) => {
    return (
      <div className='mb-2 text-light'>
        <span className='rounded-circle bg-gradiente-primary py-2 px-3'>
          {props.num}
        </span>
      </div>
    )
  }

  return (
    <section>
      <Container className='content Manual'>
        <h2>Manual do produtor</h2>
        <p>Primeiramente gostaríamos de compartilhar com você o nosso mantra.</p>
        <p className='text-secondary-dark'><strong>Impulsionar suas vendas e proporcionar uma experiência inigualável ao nosso assinante.</strong></p>
        <p>Aqui você encontrará informações e instruções úteis sobre o seu perfil de produtor de conteúdos. Nosso objetivo é capacitá-lo a utilizar todas as funcionalidades da TokPlace.com com o máximo de aproveitamento e esclarecer eventuais dúvidas que você ainda tenha sobre a nossa plataforma.</p>
        <Bullets num='1'/><p>Aqui te ensinaremos a criar o seu perfil de produtor de conteúdo. É muito fácil! Acompanhe abaixo.</p>
        <ol>
          <li>Antes de iniciar, caso você já tenha realizado um cadastro e feito login na plataforma, desconecte-se da sua conta acessando o menu e clicando em “Sair”.</li>
          <li>Procure na sua caixa de e-mail o convite que você recebeu e acesse o link com a descrição “Clique aqui para aceitar o convite”.</li>
          <li>
            <p>Realize seu cadastro preenchendo todos os dados solicitados, crie sua senha, aceite os termos gerais de uso e política de privacidade e clique em “Cadastrar”, na tela abaixo:</p>
            <img src={img01} />
          </li>
          <li><p>Na tela abaixo, realize o seu login para acessar sua conta.</p><img src={img02} /></li>
          <li><p>Na tela abaixo, clique em “Criar meu perfil”.</p><img src={img03} /></li>
          <li>
            <p>Na tela abaixo, preencha os dados do seu Perfil de produtor. Ao finalizar, clique em “Salvar Perfil”.</p>
            <img src={img04} />
            <ul>
              <li>Descrição dos campos acima:</li>
              <li>Nome de exibição – Esse nome aparecerá para os seus usuários e facilitará que seus potenciais assinantes lhe identifiquem.</li>
              <li>Nome de usuário – Aqui você poderá inserir o nome que já utiliza em outras redes sociais, de forma a facilitar sua identificação. Esse nome fará parte do seu link de acesso direto, como poderá ser visto no campo “Sua URL de acesso ”.</li>
              <li>Bio – Use esse espaço para fazer a descrição do seu perfil, informando ao interessado o que ele poderá esperar do seu conteúdo.</li>
              <li>Assinatura do seu conteúdo – Informe o preço mensal do seu conteúdo (mínimo de R$9,99). Automaticamente o sistema gerará um valor para contratação trimestral (com 10% de desconto), o qual também estará disponível para os seus assinantes.</li>
            </ul>
            <p>Saiba:</p>
            <p>A recorrência no cartão de crédito do assinante obedecerá ao período contratado por ele, até que seja solicitado o cancelamento. </p>
            <p>Ex. Na recorrência mensal, será debitado todos os meses o mesmo valor do cartão do usuário. Na recorrência trimestral, será debitado o mesmo valor a cada 3 meses. </p>
            <p>O assinante terá concordado com a recorrência do pagamento no ato do aceite dos termos gerais de uso.</p>
            <p>Pronto! Seu cadastro como produtor de conteúdos foi realizado com sucesso!</p>
          </li>
          <li>
            <p>Seu perfil está no ar! Agora é só customizá-lo.</p>
            <p>Para deixar sua página mais atraente, na barra inferior, clique na engrenagem para adicionar uma foto de perfil e uma foto de capa. No mesmo local você também poderá alterar suas informações da BIO e o valor da sua assinatura.</p>
            <img src={img05} />
            <p>PRONTO!</p>
            <p>SEU PERFIL FOI CRIADO COM SUCESSO E VOCÊ JÁ ESTÁ PRONTO PARA VENDER</p>
          </li>
        </ol>

        <Bullets num='2'/><p>Entenda o seu Perfil de Produtor de conteúdos, e conheça os prazos e limites para retiradas.</p>
        <ul>
          <li>
            <p>Funções da barra de navegação</p>
            <img src={img06} />
            <ul className='list-unstyled'>
              <li><img src={img07} />Acesso direto à lista de assinaturas que você possui.</li>
              <li><img src={img08} />Acesso direto à busca por novos produtores de conteúdo.</li>
              <li><img src={img09} />Acesso direto ao seu perfil de produtor de conteúdos.</li>
              <li><img src={img10} />Ver o seu perfil que os seus assinantes possuem acesso.</li>
              <li><img src={img11} />Postar vídeos e fotos no Storie ou na Galeria.</li>
              <li><p><img src={img12} />Configurar os dados do seu perfil de produtor de conteúdos.</p></li>
            </ul>
          </li>
          <li>
            <p>Postar seus conteúdos</p>
            <p>Para postar novas fotos e vídeos, você também poderá postar diretamente pelo botão “Postar”, optando entre Storie ou Galeria, conforme abaixo:</p>
            <img src={img13} />
            <img src={img14} />
            <p>Lembre-se que os stories ficarão disponíveis por 24 horas para os seus assinantes, enquanto a galeria ficará disponível pelo tempo que você os mantiver no seu feed.</p>
            <ul>
              <li>O limite máximo para postagem de vídeos é de 300 MB por arquivo (stories e galeria)</li>
              <li>Na TokPlace você poderá postar vídeos sem limite de tempo, desde que respeitado o limite máximo do tamanho do arquivo.</li>
              <li><p>O crop ideal de fotos e vídeos é 3:5, igual no Instagram®️. No entanto, você pode postar fotos e vídeos de qualquer tamanho. A plataforma realizará o ajuste para a melhor resolução.</p></li>
            </ul>
          </li>
          <li>
            <p>Funções da barra de menu superior</p>
            <img src={img15} />
            <ul>
              <li>Perfil: Acesso ao seu perfil de produtor de conteúdos.</li>
              <li>Assinaturas: Acesso à lista de assinaturas que você possui.</li>
              <li>Enviar convite: Funcionalidade que permite você convidar seus amigos para tornarem-se produtores de conteúdos na TokPlace (quantidade limitada).</li>
              <li>Faturamento: Tela para acompanhar os seus assinantes, seu faturamento e solicitar retiradas.</li>
              <li><p>Sair: Logoff da plataforma.</p></li>
            </ul>
          </li>
          <li>
            <p>Para visualizar os seus assinantes e acessar seus dados de faturamento, vá no menu e clique em “Faturamento”.  Você verá a tela abaixo:</p>
            <img src={img16} />
            <p>Descrição dos campos acima:</p>
            <p>”Saldo a liberar” - mostra o valor total das vendas que ainda não completou o prazo para retirada.</p>
            <p>Os pagamentos recebidos são liberados para retirada após os seguinte prazos:</p>
            <ul>
              <li>Pix: 7 dias após a confirmação da compra de cada assinatura.</li>
              <li><p>Cartão de crédito: 30 dias após a confirmação da compra de cada assinatura.</p></li>
            </ul>
            <p>”Disponível para retirada”  - mostra o valor total das vendas que completou o prazo e que já pode ser acumulado para solicitação de retirada.</p>
            <p>Observe que o resgate mínimo é R$ 500,00. Sendo assim, o sistema somente habilitará o botão de “Solicitar Retirada” após o valor ”Disponível para retirada”  tiver ultrapassado esta quantia.</p>
          </li>
        </ul>

        <Bullets num='3'/><p>Como realizar a retirada dos valores acumulados pelas suas vendas?</p>
        <ol>
          <li>Vá em “Faturamento” na barra de menu superior.</li>
          <li>
            <p>Caso o seu “Disponível para retirada”  tenha atingido o valor de resgate mínimo (R$ 500,00), o botão “Solicitar Retirada”  será automaticamente habilitado.</p>
            <p>Clique nele.</p>
            <img src={img17} />
          </li>
          <li>
            <p>Preencha todos os campos abaixo e leia os termos de uso, compra e privacidade. Estando de acordo, é só clicar em “Confirmar Retirada”.</p>
            <p>O sistema solicitará os seus dados bancários. Você poderá escolher entre receber por PIX ou Transferência Bancária (DOC ou TED).</p>
            <p>Importante saber:</p>
            <ul>
              <li>Uma vez solicitado o resgate, a TokPlace.com realizará o depósito na conta indicada e emitirá a nota fiscal referente aos serviços prestados.</li>
              <li>A TokPlace depositará os valores em até 1 dia útil.</li>
              <li>Você receberá um e-mail com o status do seu saque.</li>
            </ul>
          </li>
        </ol>

        <Bullets num='4'/><p>Usuários importados de plataformas legadas (antigas)</p>
        <p>Nós entendemos a dificuldade que você poderá encontrar caso já esteja trabalhando em alguma plataforma e decida migrar para a TokPlace.</p>
        <p>Ter assinantes em mais de uma plataforma, além de dificultar a postagem de conteúdos, também pode reduzir a qualidade do seu trabalho, gerando reclamações dos seus assinantes.</p>
        <p>Pensando nisso, disponibilizamos um processo de importação de usuários oriundos de plataformas legadas (anteriores à TokPlace).</p>
        <p>Isso significa que, caso você tenha optado em fazer da TokPlace a sua única plataforma de conteúdos e possua acima de 100 usuários em outra plataforma, podemos disponibilizar acesso aos assinantes que compraram a sua assinatura através de outra plataforma.</p>
        <p>Caso você se encontre nesta condição e cumpra com os requisitos acima, entre em contato com a nossa equipe técnica através do e-mail sac@tokplace.com para avaliarmos a possibilidade de lhe disponibilizar este serviço.</p>
        <p>IMPORTANTE</p>
        <p>O objetivo dessa funcionalidade é facilitar o dia-a-dia da nossa produtora, consolidado a divulgação dos seus conteúdos em um único lugar. No entanto, para isso, ela não deverá dar continuidade nas vendas de assinaturas em outras plataformas. Todas as novas vendas de assinaturas deverão ser feitas através da TokPlace.</p>
        <p>Sendo assim, uma vez importada a sua lista de assinantes oriundos de outra plataforma, a quantidade de assinantes não poderá aumentar nos meses seguintes.</p>
        <p>Ou seja, a lista que será disponibilizada mensalmente pela produtora à TokPlace deverá conter somente os usuários que ainda estão ativos na plataforma antiga e o número de assinantes importados, necessariamente, deverá ser sempre inferior ao mês anterior.</p>
        <p>Comissão sobre os usuários importados de outras plataformas:</p>
        <p>Para cada usuário importado será realizada a cobrança de comissão de 6,9% sobre o valor cobrado ao seu assinante.</p>
        <p>Caso você tenha assinantes importados, o total da comissão a ser paga à TokPlace pelo uso da plataforma será mostrado no campo “Comissão assinantes de outras plataformas”, conforme imagem abaixo:</p>
        <img src={img18} />
        <p>Você não precisará transferir à TokPlace nenhum valor para pagar a comissão pelo uso deste serviço.</p>
        <p>Parte das suas vendas realizadas através da TokPlace serão utilizadas para quitar o valor devido. Este processo é automático e totalmente transparente.</p>
        <p>Observe que o valor “Disponível para retirada” apresentado (vendas que completaram 30 dias) já estará descontado da comissão a ser paga pelos usuários importados. Ou seja, o valor deste campo representa o montante líquido que você poderá sacar.</p>
        <p>Ao realizar a sua retirada, a comissão a ser paga será imediatamente quitada e o valor será zerado, até que haja uma nova importação no próximo mês.</p>

        <Bullets num='5'/><p>Qual o valor das comissões cobradas pela TokPlace.com na venda de assinaturas?</p>
        <p>A TokPlace.com possui uma tabela progressiva de comissões, a qual varia de acordo com o valor cobrado pelo produtor de conteúdo.</p>
        <img src={img19} style={{ width: 480 + 'px' }}/>
        <p>*Todas as taxas referentes às transações por cartões de crédito já estão incluídas.</p>
        <ul>
          <li>Nesse momento a nossa plataforma aceita pagamento com cartões de crédito (diversas bandeiras) e PIX. Muito em breve teremos a opção de boleto.</li>
          <li>Vendas no crédito são somadas ao seu “Saldo liberado” após 30 dias e por PIX após 7 dias. Esse prazo se dá devido o direito de arrependimento de 7 dias do cliente, por força de lei (CDC - Código de Defesa do Consumidor).</li>
          <li>A TokPlace não utiliza plataformas de pagamento intermediárias, possuindo um sistema de transação direta com algumas das maiores adquirentes do mercado (responsáveis pela cobrança no cartão de crédito). Isso garante maior segurança no recebimento dos valores referentes às suas vendas.</li>
        </ul>
        <p>Isso torna a TokPlace.com a opção de mercado mais segura do mercado para os produtores de conteúdo.</p>
        <p>Lembre-se!</p>
        <p>Nosso objetivo é o mesmo – Impulsionar suas vendas e proporcionar uma experiência inigualável ao nosso assinante.</p>
      </Container>
    </section>
  )
}

export default Manual
