function formattedPhone (v) {
  v = v.replace(/\D/g, '') // Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/g, '($1) $2') // Coloca parênteses em volta dos dois primeiros dígitos
  v = v.replace(/(\d)(\d{4})$/, '$1-$2') // Coloca hífen entre o quarto e o quinto dígitos
  return v
}

function formattedCPF (v) {
  v = v.replace(/\D/g, '')

  v = v.replace(/(\d{3})(\d)/, '$1.$2')
  v = v.replace(/(\d{3})(\d)/, '$1.$2')
  v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')

  return v
}

function formattedCPFCnpj (v) {
  // Remove tudo o que não é dígito
  v = v.replace(/\D/g, '')

  if (v.length <= 13) {
    // CPF
    // Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2')

    // Coloca um ponto entre o terceiro e o quarto dígitos
    // de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d)/, '$1.$2')

    // Coloca um hífen entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  } else {
    // CNPJ
    v = v.replace(/\D/g, '') // Remove tudo o que não é dígito
    v = v.replace(/^(\d{2})(\d)/, '$1.$2') // Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') // Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2') // Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/(\d{4})(\d)/, '$1-$2') // Coloca um hífen depois do bloco de quatro dígitos
  }

  return v
}

function testaCPF (strCPF) {
  let Soma
  let Resto
  Soma = 0
  if (strCPF === '00000000000') return false

  for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
  Resto = (Soma * 10) % 11

  if ((Resto === 10) || (Resto === 11)) Resto = 0
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false

  Soma = 0
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
  Resto = (Soma * 10) % 11

  if ((Resto === 10) || (Resto === 11)) Resto = 0
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false
  return true
}

function formattedMoeda (v) {
  v = v.replace(/\D/g, '')
  v = v.replace(/(\d+)(\d{2})/, '$1.$2')
  return v
}

function removeMask (v) {
  v = v.replace(/[^0-9]/g, '')
  return v
}

function onlyNumbers (v) {
  v = v.replace(/\D/g, '')
  return v
}

export { formattedPhone, formattedCPF, formattedCPFCnpj, testaCPF, formattedMoeda, removeMask, onlyNumbers }
