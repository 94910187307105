import React, { useState, useEffect } from 'react'
import { Container, Button, Form, Card } from 'react-bootstrap'
import { alert, confirm } from 'react-bootstrap-confirmation'
import { Validate, femail } from '../../utils/FieldsConstrains'
import ButtonLoading from '../../components/ButtonLoading'
import { LoadingItens } from '../../components/Loadings'
import { kondutoPage } from '../../services/konduto'
import api from '../../services/api'
import '../../styles/forms.scss'

function SendInvitation () {
  const initialValues = { email: '' }
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState(initialValues)
  const [validated, setValidated] = useState(false)
  const [btnloading, setBtnloading] = useState(false)
  const [quota, setQuota] = useState({ limit: 0, usage: 0 })

  useEffect(() => {
    kondutoPage('send-invitation')
    getQuota()
  }, [])

  function getQuota () {
    try {
      api.get('/invites/quota').then((res) => {
        setQuota(res.data)
      })
    } catch (error) {
      alert('Erro ao solicitar o convite', { okButtonStyle: 'danger' })
    }
  }

  const handlerChange = (event) => {
    const { name, msg, vformated } = Validate(event)
    setValues({ ...values, [name]: vformated })
    setErrors({ ...errors, [name]: msg })

    event.preventDefault()
    event.stopPropagation()
    setValidated(true)
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const { msgErros, isValidity } = Validate(event)
    setErrors(msgErros)

    if (isValidity) {
      createInvite()
    }

    setValidated(true)
  }
  async function createInvite () {
    if (values.email !== '') {
      const data = { guest_email: values.email, type: 'affiliate' }
      try {
        setBtnloading(true)
        await api.post('/invites', data).then((res) => {
          alert(`Convite para ${values.email} enviado com sucesso!`, { okButtonStyle: 'success' })
          getQuota()
          setValues({ ...values, email: '' })
          setBtnloading(false)
        })
      } catch (error) {
        alert('Erro ao solicitar o convite', { okButtonStyle: 'danger' })
        setBtnloading(false)
      }
    }
  }

  return (
    <section>
      <Container className={'content'}>
        <Card className={'col-md-7 col-lg-5 mx-auto pt-3'}>
          <Card.Body>
            <Card.Title>
              {quota && (quota.limit === 1 && 'Ilimitado!')}
              {quota && (quota.limit !== 1 && quota.usage + '/' + quota.limit)}
            </Card.Title>
            <Form className='mt-0' noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Label className={'font-weight-light mb-4'}>
                <p className='lead'><strong>Convide novos produtores afiliados e receba 3%* de comissão sobre tudo que ele vender!</strong></p>
                <p>Você pode enviar convites para os seus amigos tornarem-se seus produtores de conteúdo na TokPlace!</p>
                <p>Caso o cadastro seja realizado através do seu convite, automaticamente a pessoa se tornará um produtor afiliado à você.</p>
                <p>Aproveite bem os seus convites porque a quantidade é limitada!</p>
                <hr/>
                <p>
                  <small>
                    *A comissão é paga pela TokPlace, e não pelo produtor afiliado. Todos saem ganhando!<br/>
                    *Os chargebacks ocorridos nas vendas dos seus afiliados são automaticamente descontados.
                  </small>
                </p>
              </Form.Label>
              <Form.Group>
                <Form.Control
                  {...femail.fields}
                  onChange={handlerChange}
                  defaultValue={values.email}
                />
                <Form.Control.Feedback type={'invalid'}>{errors.email}</Form.Control.Feedback>
              </Form.Group>
              <Button type='submit' className={'w-100'} variant="submit" disabled={btnloading}>
                <ButtonLoading label='Enviar convite' active={btnloading} />
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </section>
  )
}

export default SendInvitation
