import api from './api'

/**
 * @param string nickname
 */
export default (nickname, setState = null) => {
  return api.get(`/${nickname}/info`).then(({ data }) => {
    if (setState) {
      setState(data)
    }
    return data
  })
}
