import React, { useEffect, useState, useRef } from 'react'
import { Container, Card, Button } from 'react-bootstrap'
import api from '../../services/api'
import { getCoverURL, dataURLtoBlob } from '../../services/utils.js'
import { alert } from 'react-bootstrap-confirmation'
import { BiImageAdd } from 'react-icons/bi'
import ButtonFiles from '../../components/ButtonFiles/Index'
import LogoSymbol from '../../assets/logo/tokplace_symbol.svg'

function EditCover () {
  const [user, setUser] = useState()
  const [cover, setCover] = useState()
  const [preview, setPreview] = useState()
  const canvas = useRef()
  const btnsave = useRef()

  useEffect(() => {
    getUser()
  }, [])
  useEffect(() => {
    openImageInCanvas()
  }, [cover])

  const getUser = async () => {
    // setLoading({ ...loading, load: true })
    const { data } = await api.get('/users/me')
    if (data) {
      // console.log('data', data)
      setUser(data)
      setCover(data?.cover === null ? LogoSymbol : getCoverURL(data))
      // setLoading({ ...loading, load: false })
    }
  }
  const openImageInCanvas = () => {
    // console.log('avatar', avatar)
    const ctx = canvas.current.getContext('2d')
    var dwidth = 1280
    var dheight = 380
    canvas.current.width = dwidth
    canvas.current.height = dheight
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.src = cover
    img.onload = () => {
      const ratio = img.width / img.height
      // const wide = img.width > img.height

      var width = dwidth
      var height = dwidth / ratio

      var x = 0
      var y = (-height + dheight) / 2

      // console.log('ratio', ratio)
      // console.log('wide?', img.width > img.height)
      // console.log('imagem width height', img.width, img.height)
      // console.log('position x y width height', x, y, width, height)
      ctx.drawImage(img, x, y, width, height)
      if (cover.indexOf('blob') === 0) {
        var blobObject = dataURLtoBlob(ctx.canvas.toDataURL('image/jpeg', 1.0))
        setPreview(blobObject)
      }
    }
  }
  const handleGetNewImage = (event) => {
    const [file] = event.currentTarget.files
    setCover(URL.createObjectURL(file))
    // console.log('handlerGetNewImage', URL.createObjectURL(file))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    btnsave.current.disabled = true
    // console.log('handleSubmit', preview)
    const formData = new FormData()
    formData.append('file', preview)
    formData.append('upload_preset', 'xw4yrog1')

    api.post('/users/upload-image/cover', formData).then((res) => {
      // setLoading({ ...loading, load: false })
      window.location.reload()
    }).catch((error) => {
      // setLoading({ ...loading, load: false })
      alert('Falha ao buscar seus dados', { okButtonStyle: 'danger' })
    })
  }

  return (
    <section>
      <Container className={'content bg-light'}>
        <Card className={'col-md-7 col-lg-5 mx-auto pt-3'}>
          <Card.Header as="h5">Editar Capa</Card.Header>
          <canvas ref={canvas} className="mx-auto py-0 shadow-sm" style={{ maxHeight: '100%', maxWidth: '100%' }}></canvas>
          <Card.Body>
            { preview &&
              <Button ref={btnsave} className='w-100 mb-2 py-4' onClick={ handleSubmit }>
                Salvar
              </Button>
            }
            <ButtonFiles onChange={ handleGetNewImage }
              className={'btn btn-light border border-secondary-light w-100'}
              context='avatar'
              title={'Selecionar'}
              icon={<BiImageAdd size={25}/>}/>
          </Card.Body>
        </Card>
      </Container>
    </section>
  )
}
export default EditCover
