import TimeAgo from 'javascript-time-ago'
import ptBR from 'javascript-time-ago/locale/pt'

TimeAgo.addDefaultLocale(ptBR)

const createMediaURL = (producer, type) => {
  return `${process.env.REACT_APP_API_URL}/producers/${producer.id}/media/${type}`
  // return `${process.env.REACT_APP_API_URL}/producers/${producer.id}/media/${type}?time=${Math.random()}`
}

const getCoverURL = (producer) => createMediaURL(producer, 'cover')

const getAvatarURL = (producer) => createMediaURL(producer, 'avatar')

const getMediaURL = (story) => {
  const token = sessionStorage.getItem('token')
  return `${process.env.REACT_APP_API_URL}/stories/${story.thumbnail_small}?token=${token}&type=thumbnail_small`
}
const getThumbnailURL = (story) => {
  const token = sessionStorage.getItem('token')
  // return `/stories/${story.thumbnail}?token=${token}&type=thumbnail`
  return `${process.env.REACT_APP_API_URL}/stories/${story.thumbnail}?token=${token}&type=thumbnail`
}

const time_ago = (dateToConvert) => {
  /**
   * @todo BUG: Safari
   */
  if (dateToConvert) {
    const [ymd, time] = dateToConvert.split(' ')
    const [y, m, d] = ymd.split('-')
    const mdyTime = `${m}/${d}/${y} ${time}`
    dateToConvert = mdyTime
  }
  const reduceMS = 3600 * 1000 * 3
  const baseDate = (dateToConvert
    ? Date.parse(dateToConvert) - reduceMS
    : Date.now())
  const timeAgo = new TimeAgo()
  return timeAgo.format(baseDate)
}

const currencyFormat = (value, onlyNumber = false) => {
  let nativeFormat = {}
  if (onlyNumber) {
    nativeFormat = {
      maximumFractionDigits: 2
    }
  } else {
    nativeFormat = {
      style: 'currency',
      currency: 'BRL'
    }
  }
  return new Intl.NumberFormat('pt-BR', nativeFormat).format(value)
}

const dataURLtoBlob = (dataurl) => {
  var arr = dataurl.split(',')
  var mime = arr[0].match(/:(.*?);/)[1]
  var bstr = atob(arr[1])
  var n = bstr.length
  var u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

export {
  getCoverURL,
  getAvatarURL,
  time_ago,
  currencyFormat,
  getMediaURL,
  getThumbnailURL,
  dataURLtoBlob
}
