import React, { useState, useEffect } from 'react'
import { Container, Button, Form, Card } from 'react-bootstrap'
import ButtonLoading from '../../components/ButtonLoading'
import { Validate, fpolicy, fpaytransfer, ftypepix, fnumpix, fcpf, fagbank, fccbank, fbank } from '../../utils/FieldsConstrains.js'
import { currencyFormat } from '../../services/utils'
import { Link, useHistory } from 'react-router-dom'
import immutableBanks from '../../utils/bank.js'
import immutableTypepix from '../../utils/type-pix.js'
import api from '../../services/api'
import { alert, confirm } from 'react-bootstrap-confirmation'
import { kondutoPage } from '../../services/konduto'
import '../../styles/forms.scss'

function ProducerPaymentForm (query) {
  const history = useHistory()
  const initialValues = {
    cpf: '', bank: '', agbank: '', ccbank: '', paytransfer: undefined, typePix: undefined, numpix: '', policy: ''
  }
  const [validated, setValidated] = useState(false)
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState(initialValues)
  const [btnloading, setBtnloading] = useState(false)
  const [withdrawalValue, setWithdrawalValue] = useState()
  useEffect(() => {
    kondutoPage('producer-payment')
    const { valueProps } = (query.location && query.location.state) || {}
    if (!valueProps) {
      history.push('/billing')
    }
    setWithdrawalValue(valueProps)
  }, [])

  const FildsPaymentPix = (
    <div>
      <Form.Group>
        <Form.Control as="select" custom
          {...ftypepix.fields}
          onChange={(e) => handlerChange(e)}
          defaultValue={values.typepix}
        >
          <option value=''>Selecione</option>
          {immutableTypepix.map((item, index) => (
            <option key={index} value={item.value}>{item.label}</option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type={'invalid'}>{errors.typepix}</Form.Control.Feedback>
      </Form.Group>
      {values.typepix &&
      <Form.Group>
        <Form.Label className={'text-muted'}>Chave PIX (CPF, telefone, codigo pix)</Form.Label>
        {values.typepix === 'email' && <Form.Control {...fnumpix.fields} type='email' maxlength={50} value={values.numpix} onChange={(e) => handlerChange(e)}/>}
        {values.typepix === 'cell' && <Form.Control {...fnumpix.fields} type='tel' maxlength={15} value={values.numpix} onChange={(e) => handlerChange(e)}/>}
        {values.typepix === 'cpf_cnpj' && <Form.Control {...fnumpix.fields} type='cpf_cnpj' maxlength={15} value={values.numpix} onChange={(e) => handlerChange(e)}/>}
        {values.typepix === 'cpf_cnpj' && <Form.Text className="text-muted">Somente numeros</Form.Text>}
        {values.typepix === 'random' && <Form.Control {...fnumpix.fields} type='text' maxlength={50} value={values.numpix} onChange={(e) => handlerChange(e)}/>}
        <Form.Control.Feedback type={'invalid'}>{errors.numpix}</Form.Control.Feedback>
      </Form.Group>
      }
    </div>
  )
  const FildsPaymentBank = (
    <div>
      <Form.Label>Informações bancárias</Form.Label>
      <Form.Group>
        <Form.Label className={'text-muted'}>Nome do Banco</Form.Label>
        <Form.Control as="select" custom
          {...fbank.fields}
          defaultValue={values.bank}
          onChange={(e) => handlerChange(e)}
        >
          <option value=''>Selecione</option>
          {immutableBanks.map((item, index) => (
            <option key={index}>{`${item.code}-${item.name}`}</option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type={'invalid'}>{errors.bank}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label className={'text-muted'}>Agência</Form.Label>
        <Form.Control
          {...fagbank.fields}
          value={values.agbank}
          onChange={(e) => handlerChange(e)}
        />
        <Form.Control.Feedback type={'invalid'}>{errors.agbank}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label className={'text-muted'}>Conta</Form.Label>
        <Form.Control
          {...fccbank.fields}
          value={values.ccbank}
          onChange={(e) => handlerChange(e)}
        />
        <Form.Control.Feedback type={'invalid'}>{errors.ccbank}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label className={'text-muted'}>CPF</Form.Label>
        <Form.Control
          {...fcpf.fields}
          value={values.cpf}
          onChange={(e) => handlerChange(e)}
        />
        <Form.Control.Feedback type={'invalid'}>{errors.cpf}</Form.Control.Feedback>
      </Form.Group>
    </div>
  )
  const FieldPaymentPolicy = (
    <div>
      <Form.Group>
        <Form.Check
          {...fpolicy.fields}
          label="Declaro estar de acordo com os termos de uso do TokPlace para retirada de valores da minha conta."
          feedback={errors.policy}
          isInvalid={true}
          defaultValue={values.policy}
          onChange={(e) => handlerChange(e)}
        />
        <Form.Control.Feedback type={'invalid'}>{errors.policy}</Form.Control.Feedback>
      </Form.Group>
      <Button type={'submit'} disabled={!values.policy} className={'w-100'} variant="submit">
        <ButtonLoading label='Confirmar retirada' active={btnloading} />
      </Button>
    </div>
  )

  const handlerChange = (event) => {
    if (event.target.name === 'policy') {
      event.target.value = event.target.checked === true ? 1 : 0
    }
    // console.log(event.target.value)

    const { name, msg, vformated } = Validate(event)

    setValues({ ...values, [name]: vformated })
    setErrors({ ...errors, [name]: msg })
    // event.preventDefault()
    // event.stopPropagation()
    setValidated(true)
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const { msgErros, isValidity } = Validate(event)
    setErrors(msgErros)

    if (isValidity) {
      sendPayment()
    }

    setValidated(true)
  }

  const sendPayment = () => {
    // currencyFormat(withdrawalValue, true).replace(',', '.'),
    setBtnloading(true)
    const paramsBank = {
      withdrawal_value: withdrawalValue,
      agency: values.agbank,
      account: values.ccbank,
      cpf_cnpj: values.cpf,
      bank_name: values.bank,
      type: values.paytransfer,
      terms: values.policy
    }
    const paramsPix = {
      withdrawal_value: withdrawalValue,
      type: values.paytransfer,
      pix_type: values.typepix,
      pix_key: values.numpix,
      terms: values.policy
    }
    const data = values.paytransfer === 'bank_transfer' ? paramsBank : paramsPix
    if (values.policy) {
      api.post('/withdrawal-transactions', data).then(res => {
        history.push('/billing')
        alert('Seu Pedido foi efetuado com sucesso', { okButtonStyle: 'success' })
      }).catch(erro => {
        alert('Por favor, revise seus dados e tente novamente', { okButtonStyle: 'danger' })
      }).finally(() => {
        setBtnloading(false)
      })
    } else {
      alert('Você deve aceitar os termos para continuar.', { okButtonStyle: 'danger' })
      setBtnloading(false)
    }
  }
  // console.log(values.numpix)
  return (
    <section>
      <Container className={'content'}>
        <Card className={'col-md-6 col-lg-5 mx-auto pt-3'}>
          <Card.Body>
            <Card.Title className={'mb-0 mt-3'}>Valor da retirada: <small>{currencyFormat(withdrawalValue !== undefined ? withdrawalValue : 0)}</small></Card.Title>
          </Card.Body>
          <Card.Body>
            <Form noValidate validated={validated} onSubmit={(e) => handleSubmit(e)}>
              <Form.Label className={'text-muted'}>Tipo de Transferência</Form.Label>
              <Form.Group>
                <Form.Check inline {...fpaytransfer.fields} onChange={handlerChange} label='CHAVE PIX' value='pix' />
                <Form.Check inline {...fpaytransfer.fields} onChange={handlerChange} label='(DOC/TED)' value='bank_transfer' />
              </Form.Group>
              {values.paytransfer && (values.paytransfer === 'pix' ? FildsPaymentPix : FildsPaymentBank)}
              <Form.Group>
                <Form.Row>
                  {/* <Form.Label className={'w-100 mb-0'}>Termos e Política de privacidade.</Form.Label> */}
                  <Link exact="true" to="//www.tokplace.com/termos-de-uso.html" target="_blank" className="col-12 text-primary text-decoration-none w-100">
                    Termos de uso
                  </Link>
                  <Link exact="true" to="//www.tokplace.com/termos-de-compra.html" target="_blank" className="col-12 text-primary text-decoration-none w-100">
                    Termos de compra
                  </Link>
                  <Link exact="true" to="//www.tokplace.com/politica-privacidade.html" target="_blank" className="col-12 text-primary text-decoration-none w-100">
                    Política de privacidade
                  </Link>
                </Form.Row>
              </Form.Group>
              {values.paytransfer && FieldPaymentPolicy}
              <Form.Label className={'text-muted'}>
                Após confirmação, o valor será transferido para conta solicitada em até 72 horas úteis.
              </Form.Label>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </section>
  )
}
export default ProducerPaymentForm
