import React from 'react'
import { LogoSymbolTriadiAnime } from '../Logo/index'

function ButtonLoading (props) {
  // <i className="spinner-border spinner-border-sm mr-2"></i>
  const text = props.aviso || 'Loading...'
  return (
    <>
      {
        props.active
          ? <span><LogoSymbolTriadiAnime /> {text}</span>
          : <div className="my-2">{ props.label }</div>
      }
    </>
  )
}
export default ButtonLoading
