import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Container, Button, Form, Card, InputGroup, ListGroup } from 'react-bootstrap'
import { alert, confirm } from 'react-bootstrap-confirmation'
import api from '../../services/api'
import logout from '../../services/logout'
import { currencyFormat } from '../../services/utils.js'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { MdContentCopy, MdDone } from 'react-icons/md'
import ButtonLoading from '../../components/ButtonLoading'

function PaymentLink (query) {
  const history = useHistory()
  const [btnloading, setBtnloading] = useState(false)
  const [state, setState] = useState({
    copied: false,
    terms: false
  })
  const [payment, setPayment] = useState({
    confirm: false
  })
  const [terms, setTerms] = useState({
    confirm: false
  })
  const [subscription, setSubscription] = useState({})
  const [plan, setPlan] = useState({
    total: 0,
    monthly: 0
  })
  const [errors, setErrors] = useState({})
  const errored = useMemo(() => Object.values(errors).some(Boolean), [errors])
  const onErrorChange = useCallback(
    (id, error) => setErrors((prevErrors) => ({ ...prevErrors, [id]: error })),
    []
  )
  useEffect(() => {
    const { subscription, plan } = (query.location && query.location.state) || {}
    if (!subscription) {
      history.push('/')
    }
    setSubscription(subscription)
    setPlan(plan)
  }, [])

  const getUser = async () => {
    try {
      const { data } = await api.get('/users/me')
      const is_blocked = data.is_blocked
      if (is_blocked) {
        logout()
        history.push('/login')
      }
    } catch (error) {
    }
  }
  const ConfirmacaoForm = (
    <Form>
      <Form.Label className='mb-4'>Antes de prosseguir, certifique-se de que já efetuou a transação pix de {currencyFormat(plan.total)} para a chave copia-e-cola acima. <strong>Não confirme antes de realizar o pagamento.</strong></Form.Label>
      <Form.Group>
        <Form.Check
          id='native-select-optgroup'
          name='confirm_payment'
          label='Confirmo que o pagamento foi efetuado na chave pix acima'
          onChange={() => payment.confirm ? setPayment({ confirm: false }) : setPayment({ confirm: true })}
        />
      </Form.Group>
      <hr />
      <Form.Group>
        <Form.Check
          id='native-select'
          name='is_older'
          label='Concordo com os termos de uso, termos de compra e política de privacidade do TokPlace.'
          onChange={() => terms.confirm ? setTerms({ confirm: false }) : setTerms({ confirm: true })}
        />
      </Form.Group>
      {(terms.confirm && payment.confirm) &&
      <Button type={'button'} className={'w-100'} variant="submit" disabled={btnloading} onClick={() => history.push('/mySignatures')}>
        <ButtonLoading label='Confirmar Pagamento' active={btnloading} />
      </Button>
      }
    </Form>
  )

  return (
    <section>
      <Container className='content'>
        <Card className={'col-md-7 col-lg-5 mx-auto pt-3'}>
          <Card.Body>
            <Card.Title className={'mb-4'}>Utilize o código abaixo para realizar o pagamento</Card.Title>
            <Form.Group>
              <Form.Control
                as='textarea'
                id='configurable-textarea'
                readOnly
                value={subscription.link}
                rows={7}
                maxRows={7}
              />
            </Form.Group>
            <CopyToClipboard text={ subscription.link } onCopy={() => setState({ copied: true })}>
              <Button className='w-100'>
                {state.copied
                  ? <span><MdDone size={25} /> Código Copiado</span>
                  : <span><MdContentCopy size={25} /> Copiar Código</span>
                }
              </Button>
            </CopyToClipboard>
            {state.copied && ConfirmacaoForm}
          </Card.Body>
        </Card>
      </Container>
    </section>
  )
}

export default PaymentLink
