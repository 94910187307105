import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import NavbarTop from '../components/Navbar/Navbar'
import isAuthenticated from '../services/IsAuthenticated.js'

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) =>
      !isAuthenticated()
        ? <><NavbarTop Menu={rest.menu}/><Component {...props} /></>
        : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    }
    />
  )
}

export default PublicRoute
