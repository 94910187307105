/*
  MANUAL DE USO
  Este recurso serve para padronizar os validadores e as mascaras dos campos dos formularios.

  1 - importar em cada formulario 'Validate' e os Campos ex.: 'fname'
  2 - criar variavel com valores iniciais dos campos
  3 - criar os useState 'validated', 'errors' e 'values' e suas configurações iniciais.
      const initialValues = {
        fname: '', fnickname: '', fbio: '', fprice: ''
      }
      const [validated, setValidated] = useState(false)
      const [values, setValues] = useState(initialValues)
      const [errors, setErrors] = useState(initialValues)
  4 - na função 'handlerChange' adicionar:
      const handlerChange = (event) => {
        const { name, msg, vformated } = Validate(event)
        setValues({ ...values, [name]: vformated })
        setErrors({ ...errors, [name]: msg })

        event.preventDefault()
        event.stopPropagation()
        setValidated(true)
      }
  5 - na função 'handleSubmit' adicionar:
      const handleSubmit = (event) => {
        event.preventDefault()
        event.stopPropagation()
        const { msgErros, isValidity } = Validate(event)
        setErrors(msgErros)
        if (isValidity) {
          startLogon()//função com as tarefas do submit
        }
        setValidated(true)
      }
  6 - na tag form adicionar 'noValidate validated={validated} onSubmit={handleSubmit}'
  7 - em cada campo do formulario adicionar os validadores ex.:
      <Form.Control
        {...fname.fields}
        onChange={handlerChange}
      />
      <Form.Control.Feedback type={'invalid'}>{errors.name}</Form.Control.Feedback>

  EXEMPLO DOS VALIDADORES JAVASCRIPT
  setCustomValidity()
  - badInput,
  - customError,
  - patternMismatch,
  - rangeOverflow,
  - rangeUnderflow,
  - stepMismatch,
  - tooLong,
  - tooShort,
  - typeMismatch,
  - valueMissing
*/
import { LensFontIcon } from 'react-md'
import api from '../services/api'
import { formattedMoeda, onlyNumbers } from './mask'
import { currencyFormat } from '../services/utils'
import creditCardType from 'credit-card-type'
import axios from 'axios'

function vcpf (event, errortext) {
  const arr_cpf = Array.from(event.value)
  let count1 = 0
  let soma1 = 0
  let resto1 = 0
  let count2 = 0
  let soma2 = 0
  let resto2 = 0
  const calc = []
  const numcpf = []

  // calcular o decimo numero
  for (let i = 10; i >= 2; i--) {
    const num = arr_cpf[count1] || 0
    const multiplicar = num * i
    calc[count1] = multiplicar
    soma1 += calc[count1]
    resto1 = soma1 * 10 % 11
    count1++
  }
  numcpf[0] = resto1 === 10 ? 0 : resto1
  // calcular o decimo primeiro numero
  for (let i = 11; i >= 2; i--) {
    const num = arr_cpf[count2] || 0
    const multiplicar = num * i
    calc[count2] = multiplicar
    soma2 += calc[count2]
    resto2 = soma2 * 10 % 11
    count2++
  }
  numcpf[1] = resto2 === 10 ? 0 : resto2
  if (parseInt(arr_cpf[9]) !== numcpf[0] || parseInt(arr_cpf[10]) !== numcpf[1] || /([0-9])\1{10}/.test(event.value)) {
    event.setCustomValidity(errortext)
    return errortext
  } else {
    event.setCustomValidity('')
  }

  return null
}

export const fpolicy = {
  fields: {
    id: 'policy',
    name: 'policy',
    type: 'checkbox',
    required: true
  }
}
export const femail = {
  fields: {
    id: 'email',
    name: 'email',
    type: 'email',
    placeholder: 'Preencha com seu e-mail',
    minlength: 5,
    maxlength: 50,
    required: true
  }
}
export const femailguest = {
  fields: {
    id: 'email',
    name: 'email',
    type: 'text',
    placeholder: 'Preencha com seu e-mail',
    minlength: 5,
    maxlength: 50,
    required: true
  }
}
export const fpassword = {
  fields: {
    id: 'password',
    name: 'password',
    placeholder: 'Preencha com sua senha',
    minlength: 8,
    maxlength: 20,
    required: true
  },
  fieldsconfirm: {
    id: 'passwordconfirm',
    name: 'passwordconfirm',
    placeholder: 'Confirme sua senha',
    minlength: 8,
    maxlength: 20,
    required: true
  },
  mensagem: (value, validity, event) => {
    if (validity.valueMissing) {
      return 'Por favor, entre com uma senha.'
    }
    if (validity.tooShort) {
      return 'Sua senha deve conter ao menos 8 caracteres.'
    }
    if (event.name === 'passwordconfirm') {
      const password = document.querySelector('#password').value
      if (password !== undefined && password !== null && value !== password) {
        event.setCustomValidity('As senhas não são iguais')
        return 'As senhas não são iguais'
      } else {
        event.setCustomValidity('')
      }
    }
    return null
  }
}
export const fname = {
  fields: {
    id: 'name',
    name: 'name',
    type: 'text',
    pattern: '([a-zA-Z]+[\\s]?[a-zA-Z]+)+',
    minLength: 5,
    maxLength: 255,
    required: true
  },
  mensagem: (value, validity, event) => {
    if (validity.patternMismatch) {
      return 'Somente letras e com apenas 1 espaço entre nomes.'
    }
    return null
  }
}
export const ftelphone = {
  fields: {
    id: 'telphone',
    name: 'telphone',
    type: 'text',
    placeholder: 'Somente números',
    pattern: '([0-9]+)',
    minlength: 9,
    maxlength: 10,
    required: true
  },
  fieldsddd: {
    id: 'telphoneddd',
    name: 'telphoneddd',
    type: 'text',
    placeholder: 'DDD',
    pattern: '([0-9]+)',
    minlength: 2,
    maxlength: 2,
    required: true
  },
  fieldstype: {
    id: 'telphonetype',
    name: 'telphonetype',
    as: 'select',
    custom: true,
    required: true
  },
  fieldstypeOption: [
    { value: '', text: 'Tipo de telefone' },
    { value: 'mobile', text: 'Celular' },
    { value: 'landline', text: 'Fixo' }
  ],
  mask: (value, event) => {
    return event.name !== 'telphonetype' ? onlyNumbers(value) : value
  }
}
export const fbirthday = {
  fields: {
    id: 'birthday',
    name: 'birthday',
    type: 'date',
    placeholder: '01/01/2020',
    required: true
  },
  mensagem: (value, validity, event) => {
    if (validity.patternMismatch) {
      return 'Somente letras e com apenas 1 espaço entre nomes.'
    }
    // const data = DateFormater(value)
    // if (value.trim().length === 0) {
    //   return 'Por favor, entre com data de nascimento.'
    // }
    // if (!/^\d{2}-\d{2}-\d{4}$/.test(data)) {
    //   return 'Por favor, entre com data válida.'
    // }
    return null
  }
}
export const fisolder = {
  fields: {
    id: 'isolder',
    name: 'isolder',
    type: 'checkbox',
    label: 'Declaro ser maior que 18 anos de idade',
    required: true
  }
}
export const fnickname = {
  fields: {
    id: 'nickname',
    name: 'nickname',
    type: 'text',
    pattern: '[a-z|0-9]+',
    minLength: 3,
    maxLength: 20,
    required: true
  },
  mask: (value, event) => {
    return value.toLowerCase()
  }
}
export const fbio = {
  fields: {
    id: 'bio',
    name: 'bio',
    type: 'text',
    minLength: 3,
    maxLength: 1000,
    required: true
  }
}
export const fprice = {
  fields: {
    id: 'price',
    name: 'price',
    type: 'text',
    minLength: 3,
    maxLength: 10
    // required: true
  },
  mensagem: (value, validity, event) => {
    if (parseFloat(formattedMoeda(value)) < parseFloat('9.99')) {
      event.setCustomValidity('Valor não permitido!')
      return 'Valor não permitido!'
      // console.log(formattedMoeda(value))
    } else {
      event.setCustomValidity('')
    }
    return null
  },
  mask: (value, event) => {
    return formattedMoeda(value)
  }
}
export const fagree = {
  fields: {
    id: 'agree',
    name: 'agree',
    type: 'checkbox',
    label: 'Declaro estar de acordo com os termos de uso, termos de compra e a política de privacidade.',
    required: true
  },
  mensagem: (value, validity, event) => {
    if (value === false) {
      return 'Campo obrigatório!'
    }
    return null
  }
}
export const fcpf = {
  fields: {
    id: 'cpf',
    name: 'cpf',
    type: 'text',
    placeholder: 'Somente números',
    maxlength: 18,
    required: true
  },
  mensagem: (value, validity, event) => {
    const type = event.attributes.type.value
    const msg = vcpf(event, 'CPF Invalido!')
    if (!msg) {
      return msg
    }
    return null
  },
  mask: (value, event) => {
    return onlyNumbers(value)
  }
}
export const fbank = {
  fields: {
    name: 'bank',
    type: 'select',
    required: true
  }
}
export const fagbank = {
  fields: {
    id: 'agbank',
    name: 'agbank',
    type: 'text',
    maxlength: 5,
    required: true
  },
  mask: (value, event) => {
    return onlyNumbers(value)
  }
}
export const fccbank = {
  fields: {
    name: 'ccbank',
    type: 'number',
    max: 9999999999,
    required: true
  }
}
export const fpaytransfer = {
  fields: {
    name: 'paytransfer',
    type: 'radio',
    required: true
  }
}
export const ftypepix = {
  fields: {
    name: 'typepix',
    required: true
  },
  mensagem: (value, validity, event) => {
    if (!value) {
      return 'Selecione um tipo de pix!'
    }
    return null
  }
}
export const fnumpix = {
  fields: {
    name: 'numpix',
    placeholder: 'Preencha com sua chave do pix',
    required: true
  },
  mask: (value, event) => {
    const type = event.attributes.type.value
    if (type === 'cpf_cnpj') {
      return onlyNumbers(value)
    } else {
      return value
    }
  }
}
export const fcardnum = {
  fields: {
    name: 'cardnum',
    type: 'text',
    maxLength: 20,
    placeholder: '0000000000000000',
    required: true
  },
  mask: (value, event) => {
    const newValue = { value: value, card: creditCardType(value)[0] }
    // const Cards = creditCardType(value)
    return newValue
  }
}
export const fcardvalidity = {
  fields: {
    name: 'cardvalidity',
    type: 'text',
    pattern: '^\\d{2}/\\d{4}$',
    placeholder: 'MM/AAAA',
    minLength: 7,
    maxLength: 7,
    required: true
  },
  mask: (value, event) => {
    const regex = /^(\d{2})(\d{4})/g
    value = value.replace(/\D/g, '')
    value = value.replace(regex, '$1/$2')
    return value
  }
}
export const fcardcvv = {
  fields: {
    name: 'cardcvv',
    type: 'text',
    placeholder: 'Preencha',
    maxLength: 3,
    required: true
  },
  mask: (value, event) => {
    return onlyNumbers(value)
  }
}
export const fcep = {
  fields: {
    name: 'cep',
    type: 'text',
    // pattern: '^\\d{5}/-\\d{3}$',
    placeholder: 'Apenas números',
    minLength: 8,
    maxLength: 8,
    required: true
  },
  mask: (value, event) => {
    // const regex = /^(\d{5})(\d{3})/g
    // value = value.replace(/\D/g, '')
    // value = value.replace(regex, '$1-$2')
    return onlyNumbers(value)
  }
}
export const faddress = {
  fields: {
    name: 'address',
    type: 'text',
    placeholder: 'Logradouro',
    minLength: 3,
    maxLength: 50,
    required: true
  }
}
export const faddressnumber = {
  fields: {
    name: 'addressnumber',
    type: 'text',
    placeholder: 'Número',
    maxLength: 20,
    required: true
  }
}
export const faddresscomplement = {
  fields: {
    name: 'addresscomplement',
    type: 'text',
    placeholder: 'Complemento',
    maxLength: 100,
    required: true
  }
}
export const faddressdistrict = {
  fields: {
    name: 'addressdistrict',
    type: 'text',
    placeholder: 'Bairro',
    maxLength: 30,
    required: true
  }
}
export const faddresscity = {
  fields: {
    name: 'addresscity',
    type: 'text',
    placeholder: 'Município',
    maxLength: 30,
    required: true
  }
}
export const faddressstate = {
  fields: {
    name: 'addressstate',
    placeholder: 'Estado',
    required: true
  }
}
export const faddresscountry = {
  fields: {
    id: 'addresscountry',
    name: 'addresscountry',
    required: true
  }
}
export const ffile = {
  fields: {
    id: 'file',
    name: 'file',
    required: true
  },
  mensagem: (value, validity, event) => {
    if (value > 200000000) {
      return 'Tamanho do arquivo não permitido'
    }
    return null
  }
}

const getFields = {
  policy: fpolicy,
  agree: fagree,
  email: femail,
  emailguest: femailguest,
  password: fpassword,
  passwordconfirm: fpassword,
  name: fname,
  telphone: ftelphone,
  telphoneddd: ftelphone,
  telphonetype: ftelphone,
  birthday: fbirthday,
  isolder: fisolder,
  nickname: fnickname,
  bio: fbio,
  price: fprice,
  paytransfer: fpaytransfer,
  typepix: ftypepix,
  numpix: fnumpix,
  cpf: fcpf,
  bank: fbank,
  agbank: fagbank,
  ccbank: fccbank,
  cardnum: fcardnum,
  cardvalidity: fcardvalidity,
  cardcvv: fcardcvv,
  cep: fcep,
  address: faddress,
  addressnumber: faddressnumber,
  addresscomplement: faddresscomplement,
  addressdistrict: faddressdistrict,
  addresscity: faddresscity,
  addressstate: faddressstate,
  addresscountry: faddresscountry,
  gallery: ffile
}

const getValidation = (fieldName, systemErro, validity, type, value) => {
  let msgErro = systemErro

  if (getFields[fieldName] !== undefined && getFields[fieldName].mensagem !== undefined) {
    const msg = getFields[fieldName].mensagem(value, validity, type)
    msgErro = msg !== null ? msg : systemErro
  }

  return msgErro
}

export const Validate = (events) => {
  const event = events.target
  // event.setCustomValidity('teste')
  let msgErros = {}
  // Se vem do submit form ou de qualquer outro change por field.
  if (events.type === 'submit') {
    const itens = event.elements
    let isValidity = true
    for (const item of itens) {
      let msg = ''

      if (item.type !== 'submit') { // ignorar dados do botão submit do formulario
        msg = getValidation(item.name, item.validationMessage, item.validity, item, item.value)
        msgErros[item.name] = msg
        if (!item.validity.valid) {
          isValidity = item.validity.valid
        }
      }
    }
    return { msgErros, isValidity }
  } else {
    const value = event.value
    // Valida os valores passados e retorna com o erro
    // console.log(event.validationMessage)
    msgErros = getValidation(event.name, event.validationMessage, event.validity, event, value)

    // Aplica mascaras nos valores se disponivel
    let valueFormated = value
    if (getFields[event.name] !== undefined && getFields[event.name].mask !== undefined) {
      valueFormated = getFields[event.name].mask(value, event)
    }

    return { name: event.name, msg: msgErros, vformated: valueFormated }
  }
}
