export const kondutoPage = (page) => {
  const period = 300
  const limit = 20 * 1e3
  let nTry = 0
  const intervalID = setInterval(function () {
    let clear = limit / period <= ++nTry
    if ((typeof (window.Konduto) !== 'undefined') && (typeof (window.Konduto.sendEvent) !== 'undefined')) {
      window.Konduto.sendEvent('page', page)
      clear = true
    }
    if (clear) {
      clearInterval(intervalID)
    }
  }, period)
}
export const kondutoCustomerId = (customerID) => {
  if (customerID !== null) {
    const period = 300
    const limit = 20 * 1e3
    let nTry = 0
    const intervalID = setInterval(function () {
      let clear = limit / period <= ++nTry
      if ((typeof (window.Konduto) !== 'undefined') && (typeof (window.Konduto.sendEvent) !== 'undefined')) {
        window.Konduto.setCustomerID(customerID)
        clear = true
      }
      if (clear) {
        clearInterval(intervalID)
      }
    }, period)
  }
}
