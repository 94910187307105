import React, { useEffect, useState } from 'react'
import { getAvatarURL } from '../../services/utils.js'
import avatarImg from '../../assets/avatar/Bitmap.png'
import './style.scss'

function getLocalStorage (key) {
  return JSON.parse(localStorage.getItem(key))
}
function setLocalStorage (key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

const Avatar = (props) => {
  const [activeEffect, setActiveEffect] = useState(false)
  useEffect(() => {
    if (props.lastPost) {
      changeLastPostActive()
    }
  }, [props.lastPost])

  function handleStopActiveEffect () {
    setActiveEffect(false)
  }
  function changeLastPostActive () {
    // localStorage.removeItem('lastPosts')
    const actualPosts = getLocalStorage('lastPosts')
    const has_story = checkStoryDays(props.lastPost.published_at)
    // console.log('actualPosts:', actualPosts)

    if (actualPosts) { // localStorage['lastPosts'] exist?
      if (actualPosts.some(item => item.user_id === props.lastPost.user_id)) { // user_id exist?
        const newObject = actualPosts.map(item => {
          if (item.user_id === props.lastPost.user_id && (item.published_at < props.lastPost.published_at || item.newdate === true)) {
            item.newdate = has_story
            item.published_at = props.lastPost.published_at
            setActiveEffect(has_story)
          }
          return item
        })
        setLocalStorage('lastPosts', newObject)
        // console.log('newObject:', newObject)
      } else {
        actualPosts.push({ ...props.lastPost, newdate: true })
        setLocalStorage('lastPosts', actualPosts)
        setActiveEffect(true)
      }
    } else {
      setLocalStorage('lastPosts', [{ ...props.lastPost, newdate: has_story }])
      setActiveEffect(has_story)
    }
  }
  function checkStoryDays (published_at) {
    const d = new Date(published_at)
    d.setDate(d.getDate() + 1)
    return Date.now() <= Date.parse(d)
  }
  // console.log('props:', props)
  let userId = ''
  let url
  if (props.me) {
    userId = JSON.parse(localStorage.getItem('user'))
    url = userId.avatar !== null ? getAvatarURL(userId) : avatarImg
  } else {
    userId = props.url
    url = (userId !== undefined && userId !== null) ? getAvatarURL(userId) : avatarImg
  }
  const size = props.size ? props.size : 90
  const bcolor = props.bcolor ? props.bcolor : 'border-light'
  return (
    <div
      className={`Avatar ${activeEffect && 'active-effect'} d-flex align-items-center justify-content-center overflow-hidden rounded-circle border border-${bcolor} border-0 ${props.className}`}
      style={{ height: size, width: size }}
      name={props.name}
      onClick={handleStopActiveEffect}
    >
      <div className='effect'></div>
      <img
        src={url}
        style={{ height: size, width: size }}
        className={`w-100 h-100 border-2 border border-${bcolor}`}
        onClick={props.onClick}
      />
    </div>
  )
}

export default Avatar
