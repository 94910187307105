import React, { useState } from 'react'
import { Container, Button, Card } from 'react-bootstrap'
import api from '../../services/api'
import { Link, useHistory } from 'react-router-dom'
import ButtonLoading from '../../components/ButtonLoading'
import { alert, confirm } from 'react-bootstrap-confirmation'
import '../../styles/forms.scss'

function Invite () {
  const history = useHistory()
  const [btnloading, setBtnloading] = useState(false)

  async function isProducer () {
    const token = localStorage.getItem('tokenInvite')
    const user = JSON.parse(localStorage.getItem('user'))
    user.is_producer = true
    localStorage.setItem('user', JSON.stringify(user))
    try {
      await api.post(`/invites/${token}/accept`)
      alert('Convite para produtor aceito com sucesso.', { okButtonStyle: 'success' })
      setTimeout(() => {
        window.location.href = '/producer-info'
      }, 3000)
    } catch (error) {
      alert('Seu convite expirou.', { okButtonStyle: 'danger' })
      setTimeout(() => {
        history.push('/login')
      }, 3000)
    }
    localStorage.removeItem('emailInvite')
    localStorage.removeItem('tokenInvite')
  }

  return (
    <section>
      <Container className={'content'}>
        <Card className={'col-md-7 col-lg-5 mx-auto pt-3'}>
          <Card.Body>
            <Card.Title>Ativar convite</Card.Title>
            <div className='form'>
              <h5 className='mb-5'>Você recebeu um convite para se tornar um produtor de conteúdo oficial da TokPlace</h5>
              <Button type='submit' className={'w-100 mb-4 py-4'} variant="default" onClick={() => { isProducer() }}>
                <ButtonLoading label='Criar meu perfil' active={btnloading} />
              </Button>
              <Link to="/validation" className='text-decoration-none'>Não, obrigado</Link>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </section>
  )
}

export default Invite
