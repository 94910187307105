import { Container, Button, ListGroup, Card, Form, Collapse } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { alert, confirm } from 'react-bootstrap-confirmation'
import { BsPlusSquare, BsPlusCircleFill } from 'react-icons/bs'
import { kondutoPage, kondutoCustomerId } from '../../services/konduto'
import api from '../../services/api'
import getVindiPlans from '../../services/getVindiPlans'
import getProducerStories from '../../services/getProducerStories'
import Avatar from '../../components/Avatar/index'
import logout from '../../services/logout'
import ButtonLoading from '../../components/ButtonLoading'
import { LoadingPage } from '../../components/Loadings'
import { Stories } from '../../components/Gallery'
import '../../styles/forms.scss'
import './style.scss'
import { getDate } from 'javascript-time-ago/gradation'

function MySignatures () {
  const history = useHistory()
  const [user, setUser] = useState()
  const [subscription, setSubscription] = useState([])
  const [subscriptionImport, setSubscriptionImport] = useState([])
  const [stories, setStories] = useState()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  function getLocalStorage (key) {
    return JSON.parse(localStorage.getItem(key))
  }
  function setLocalStorage (key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  }
  function setWatchedPost (id) {
    const lastPosts = (getLocalStorage('lastPosts')).map(item => {
      if (item.user_id === id) {
        item.newdate = false
      }
      return item
    })
    // console.log('lastPosts', lastPosts)
    setLocalStorage('lastPosts', lastPosts)
  }

  useEffect(() => {
    getUser()
    getProfileSubscritions()
    getProfileSubscritionsImport()
  }, [])

  const getUser = async () => {
    try {
      const { data } = await api.get('/users/me')
      const is_blocked = data.is_blocked
      kondutoPage('mySignatures')
      kondutoCustomerId(data.vindi_customer)
      setUser(data)
      if (is_blocked) {
        alert('Sua conta foi bloqueada entre em contato com TokPlace', { okButtonStyle: 'warning' })
        logout()
        history.push('/login')
      }
    } catch (error) {
    }
  }
  const getProfileSubscritions = () => {
    api.get('/subscriptions', {
      params: {
        status: ['active', 'pending']
      }
    }).then((res) => {
      const newData = res.data.map((item) => {
        return item
      })
      setSubscription(newData)
    }).catch(() => {
      alert('Não foi possível carregar as assinaturas', { okButtonStyle: 'danger' })
    })
  }
  const getProfileSubscritionsImport = () => {
    api.get('/subscriptions-imports-details/my').then((res) => {
      const newData = res.data.map((item) => {
        return item
      })
      setSubscriptionImport(newData)
    }).catch(() => {
      alert('Não foi possível carregar as assinaturas', { okButtonStyle: 'danger' })
    })
  }

  function handlerLoadStories (producer) {
    getProducerStories(producer.id).then(stories => {
      const token = sessionStorage.getItem('token')
      const items = stories.data.map(story => {
        const url = `${story.cdn_url}?token=${token}&type=media`
        const type = story.cdn_url.endsWith('.mp4') ? 'video' : 'image'
        return { url, type }
      })
      // console.log('Media encontrada para este produtor: ', items.length)
      if (items.length > 0) {
        setWatchedPost(producer.id)
        setStories(items)
      }
    })
  }
  function handleProfile (user) {
    history.push({ pathname: `/${user.producer.nickname}`, state: { user: user } })
  }
  function handleSearch () {
    history.push('/search')
  }
  async function handleCancelSubscription (item) {
    const accept = await confirm(`Deseja realmente cancelar a recorrência de ${item.producer.name_social}`, { okButtonStyle: 'danger' })
    // console.log(accept)
    if (accept) {
      setLoading(true)
      // console.log(item.vindi_subscription.toString())
      api.post('/subscriptions/cancel', { vindi_subscription: (item.vindi_subscription).toString() }).then(res => {
        setSubscription(
          subscription.map(item => {
            if (item.vindi_subscription === res.data[0].id.toString()) {
              item.recurrent = 0
            }
            return item
          })
        )
        alert(`Sua recorrência foi cancelada com sucesso. Você não será cobrando novamente e seu acesso estará ativo até ${remainingDateSubscription(item.activated_at, item.plan)}`, { okButtonStyle: 'success' })
        setLoading(false)
      }).catch(erro => {
        alert('Não foi possível cancelar recorrência, tente mais tarde!', { okButtonStyle: 'danger' })
      })
    }
    // Sua recorrência foi cancelada com sucesso. Você não será cobrando novamente e seu acesso estará ativo até XX/XX/XX
  }
  function remainingDateSubscription (activated_at, plan) {
    const currentDate = new Date(activated_at.replace(/-/g, '/'))
    const data = new Date(currentDate.setMonth(currentDate.getMonth() + getVindiPlans(plan)))
    return data.toLocaleDateString()
  }
  function CollapseBtnContent (props) {
    const [open, setOpen] = useState(false)
    const id = props.item.aarin_subscription || props.item.vindi_subscription
    return (
      <>
        <Button
          onClick={() => setOpen(!open)}
          aria-controls={`cancel-${id}`}
          aria-expanded={open}
          className='p-1 rounded-circle'
        >
          <BsPlusCircleFill size={30}/>
        </Button>
        <Collapse in={open} className='w-100 mt-3'>
          <div id={`cancel-${id}`}>
            { props.item.aarin_subscription && <p>Como o pagamento da sua assinatura foi realizado por PIX, o seu acesso ao conteúdo permanecerá ativo até o dia { remainingDateSubscription(props.item.activated_at, props.item.plan) }. A compra por PIX não possui recorrência e você não será cobrado novamente.</p> }
            { props.item.vindi_subscription &&
              <Button className='w-100 p-3 rounded-pill' onClick={() => handleCancelSubscription(props.item)}>
                Cancelar assinatura
              </Button>
            }
          </div>
        </Collapse>
      </>
    )
  }
  const ProfileSubscritions = subscription.map((item, index) =>
    <ListGroup.Item key={item.producer.id} className={'media row py-4 d-flex align-items-center'}>
      <Avatar url={item.producer.avatar === null ? null : item.producer}
        lastPost={item.latest_stories}
        size={90}
        className={'mr-3 float-left'}
        onClick={() => { handlerLoadStories(item.producer) }}
      />
      <div className="media-body float-left" onClick={() => { handleProfile(item) }}>
        <h5 className="mt-0 mb-1">{item.producer.name_social}</h5>
        <p className='mb-0'>{`@${item.producer.nickname}`}</p>
        {item.status === 'pending' && <p className='mt-2 mb-0'>Aguardando</p>}
        {item.status === 'active' && item.recurrent === 0 && <p className='mt-2 mb-0'>Finaliza em { remainingDateSubscription(item.activated_at, item.plan) }</p>}
      </div>
      { item.status === 'active' && item.recurrent === 1 &&
      <CollapseBtnContent item={item} />
      }
    </ListGroup.Item>
  )
  const ProfileSubscritionsImport = subscriptionImport.map((item) =>
    <ListGroup.Item key={item.producer.id} className={'media row py-4 d-flex align-items-center'}>
      <Avatar url={item.producer.avatar === null ? null : item.producer}
        size={90}
        onClick={() => { handlerLoadStories(item.producer) }}
        className={'mr-3 float-left'}
      />
      <div className="media-body float-left" onClick={() => { handleProfile(item) }}>
        <h5 className="mt-0 mb-1">{item.producer.name_social}</h5>
        <p className='mb-0'>{`@${item.producer.nickname}`}</p>
        {item.status === 'pending' && <p className='mt-2 mb-0'>Aguardando</p>}
      </div>
    </ListGroup.Item>
  )

  const Profiles = () => {
    return (
      <ListGroup variant="flush" className={'Profiles'}>
        {ProfileSubscritions}
        {ProfileSubscritionsImport}
        {(subscription.length === 0 && subscriptionImport.length === 0) &&
          <ListGroup.Item>Você ainda não possui assinaturas ativas</ListGroup.Item>}
      </ListGroup>
    )
  }
  // console.log('subscription:', subscription)
  return (
    <section>
      <Container className={'MySignatures content'}>
        <Card className={'col-md-7 col-lg-5 mx-auto pt-3'}>
          <Card.Body>
            <Card.Title>Minhas Assinaturas</Card.Title>
            {subscription.length > 0 || subscriptionImport.length > 0 ? <Profiles /> : <span>Você ainda não possui assinaturas.<br/>Utilize o botão abaixo para pesquisar novos produtores.</span>}
          </Card.Body>
          <Card.Body>
            <Card.Title>Buscar no TokPlace<br/><small>Procure por produtores de conteúdo</small></Card.Title>
            <Form className={'mb-0'}>
              <Button type='buttom' onClick={() => { handleSearch() }} variant='submit' className={'w-100 mb-0'} >
                <ButtonLoading label='Buscar por nome do produtor' active={false} />
              </Button>
            </Form>
          </Card.Body>
          <Card.Body>
            <Card.Title>Gerenciar Assinaturas</Card.Title>
            <Card.Text className='font-weight-light'>
              Para gerenciar as suas assinaturas,<br/> entre em contato através do e-mail:<br/>
              <Link href="mailto:assinaturas@tokplace.com">assinaturas@tokplace.com</Link>
            </Card.Text>
          </Card.Body>
        </Card>
        <Stories stories={stories} setStories={setStories}/>
      </Container>
      <LoadingPage show={loading} label='Cancelando assinatura...'/>
    </section>
  )
}

export default MySignatures
