import React, { useState, useEffect } from 'react'
import { useHistory, Link, Switch, NavLink } from 'react-router-dom'
import { BsSearch, BsHouse, BsGear } from 'react-icons/bs'
import { MdAddAPhoto } from 'react-icons/md'
import { AiOutlineUser } from 'react-icons/ai'
import { Container, Navbar, Row, Col, Nav, Media, Button, Modal } from 'react-bootstrap'
import isProducer from '../../services/IsProducer'
import Avatar from '../Avatar'

const NavFooter = (props) => {
  const [user, setUser] = useState()
  const [navPostsCreate, setNavPostsCreate] = useState(false)
  const [navPerfilEdit, setNavPerfilEdit] = useState(false)

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')))
  }, [])

  let margin
  if (!props.toRight && !props.toLeft) {
    margin = 'mx-auto'
  } else if (props.toRight) {
    margin = 'ml-auto'
  } else {
    margin = 'mr-auto'
  }

  const NavPerfilEdit = () => {
    return (
      <Modal show={navPerfilEdit} onHide={() => setNavPerfilEdit(false)} className={'NavFooterExternal'}>
        <Container>
          <Modal.Header closeButton>
            <h4>Atenção!</h4>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <p>É <strong>proibido</strong> fotos com nudez na Capa e no Perfil!</p>
              </Col>
              <Col md={6}>
                <div className='list-group list-group-flush'>
                  <Link to='/producer-edit-perfil' onClick={() => setNavPerfilEdit(false)} className={'list-group-item list-group-item-action'}>Editar Perfil</Link>
                  <Link to='/producer-edit-avatar' onClick={() => setNavPerfilEdit(false)} className={'list-group-item list-group-item-action'}>Editar Foto de Perfil</Link>
                  <Link to='/producer-edit-cover' onClick={() => setNavPerfilEdit(false)} className={'list-group-item list-group-item-action'}>Editar Capa de Perfil</Link>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Container>
      </Modal>
    )
  }
  const NavPostsCreate = () => {
    return (
      <Modal show={navPostsCreate} onHide={() => setNavPostsCreate(false)} className={'NavFooterExternal'}>
        <Container>
          <Modal.Header closeButton>
            <h4>Postar foto ou video</h4>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <p>Selecione no seu dispositivo fotos e videos com limite máximo de 300 MB por arquivo.</p>
              </Col>
              <Col md={6}>
                <div className='list-group list-group-flush'>
                  <Link
                    to='/producer'
                    onClick={ () => setNavPostsCreate(false) }
                    className={'list-group-item list-group-item-action'}
                    context='story'>
                    Publicar Stories
                  </Link>
                  <Link
                    to='/producer/public'
                    onClick={ () => setNavPostsCreate(false) }
                    className={'list-group-item list-group-item-action'}
                    context='public'>
                    Público
                  </Link>
                  <Link
                    to='/producer/galeria'
                    onClick={ () => setNavPostsCreate(false) }
                    className={'list-group-item list-group-item-action'}
                    context='gallery'>
                    Publicar na Galeria
                  </Link>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Container>
      </Modal>
    )
  }

  return (
    <footer>
      <Navbar variant="light" bg="primary" fixed="bottom" className={'NavFooter border-top border-5'}>
        <Container>
          {props.toLeft && props.toLeft}
          <Nav className={`${margin} text-primary-light`}>
            <Nav.Link href={'/'} className={'btn btn-transparent text-center text-primary-light mx-2'} title={'Assinaturas'}>
              <BsHouse size={25}/>
            </Nav.Link>
            <Nav.Link href={'/search'} className={'btn btn-transparent text-center text-primary-light mx-2'} title={'Search'}>
              <BsSearch size={25}/>
            </Nav.Link>
            {isProducer() &&
            <>
              <Nav.Link href={'/producer'} className={'btn btn-transparent text-center text-primary-light mx-2'} title={'Search'}>
                <Avatar me={true} size={28} />
              </Nav.Link>
              <Nav.Link href={`/${user && user.nickname}`} className={'btn btn-transparent text-center text-primary-light mx-2'} title={'User'}>
                <AiOutlineUser size={25}/>
              </Nav.Link>
              <Nav.Link as="button" className={'btn btn-transparent text-center text-primary-light mx-2'}
                onClick={() => setNavPostsCreate(true)} title={'Postar'}>
                <MdAddAPhoto size={25}/>
              </Nav.Link>
              <Nav.Link as="button" className={'btn btn-transparent text-center text-primary-light mx-2'}
                onClick={() => setNavPerfilEdit(true)} title={'Setup'}>
                <BsGear size={25}/>
              </Nav.Link>
            </>
            }
          </Nav>
          {props.toRight && props.toRight}
        </Container>
      </Navbar>
      <NavPerfilEdit />
      <NavPostsCreate />
    </footer>
  )
}

export default NavFooter
