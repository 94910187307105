import React from 'react'
import * as AiIcons from 'react-icons/ai'
import * as IoIcons from 'react-icons/io'
import { FaQuestion } from 'react-icons/fa'
import isAuthenticated from '../../services/IsAuthenticated.js'

const isLoggedInProducer = [
  {
    title: 'Perfil',
    path: '/producer',
    icon: <AiIcons.AiOutlineHome/>,
    cName: 'nav-text'
  },
  {
    title: 'Assinaturas',
    path: '/mySignatures',
    icon: <AiIcons.AiOutlineCreditCard />,
    cName: 'nav-text'
  },
  {
    title: 'Enviar convite',
    path: '/sendInvitation',
    icon: <AiIcons.AiOutlineMail />,
    cName: 'nav-text'
  },
  {
    title: 'Faturamento',
    path: '/billing',
    icon: <AiIcons.AiOutlineDollarCircle />,
    cName: 'nav-text'
  },
  {
    title: 'Manual',
    path: '/manual',
    icon: <FaQuestion size={12}/>,
    cName: 'nav-text'
  }
]

const isLoggedInUser = [
  {
    title: 'Assinaturas',
    path: '/mySignatures',
    icon: <AiIcons.AiFillCreditCard />,
    cName: 'nav-text'
  }
]

const islogout = [
  {
    title: 'Login',
    path: '/login',
    icon: <AiIcons.AiFillHome />,
    cName: 'nav-text'
  },
  {
    title: 'Cadastre-se',
    path: '/signup',
    icon: <IoIcons.IoIosPaper />,
    cName: 'nav-text'
  }
]

export const getSidebarItems = () => {
  try {
    const userString = localStorage.getItem('user')
    const user = JSON.parse(userString)
    if (isAuthenticated()) {
      if (user && (user.is_producer)) {
        return isLoggedInProducer
      } else {
        return isLoggedInUser
      }
    } else {
      return islogout
    }
  } catch {
    return islogout
  }
}
