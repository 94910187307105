import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import NavbarTop from '../components/Navbar/Navbar'
import isAuthenticated from '../services/IsAuthenticated.js'

const PrivateRoute = ({ component: Component, ...rest }) => {
  setTimeout(() => { sessionStorage.removeItem('token') }, 2 * 60 * 60 * 1000)
  return (
    <Route
    {...rest}
    render={(props) =>
      isAuthenticated()
        ? <><NavbarTop Menu={true}/><Component {...props} /></>
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }
    />
  )
}

export default PrivateRoute
