import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Button, Row, Col } from 'react-bootstrap'
import api from '../../services/api'
import { Feeds } from '../../components/Gallery'
import { kondutoPage } from '../../services/konduto'
import { LoadingPage } from '../../components/Loadings/index'
import { getThumbnailURL } from '../../services/utils'
import Avatar from '../../components/Avatar'

function Feed () {
  const history = useHistory()
  const [feeds, setFeeds] = useState({ has: true, current_page: 0, data: [] })
  const [loading, setLoading] = useState(false)
  const [producers, setProducers] = useState({ current: 0, itens: [] })
  const [user, setUser] = useState({ name: '' })

  useEffect(() => {
    kondutoPage('feed')
    getProducers()
    getFeeds()
    getUser()
  }, [])

  async function getUser () {
    const user = await JSON.parse(localStorage.getItem('user'))
    setUser(user)
    // console.log(user)
  }

  async function getFeeds () {
    setLoading(true)
    const resp = await api.get(`/users/feeds?page=${feeds.current_page + 1}`)
    setLoading(false)
    if ((resp.data.data).length === 0) {
      history.push('/mySignatures')
    }
    resp.data.current_page = resp.data.next_page_url === null ? 0 : resp.data.current_page
    const loadfeed = loadFeeds(resp.data.data)
    // console.log('loadfeed', loadfeed)
    const newData = [...feeds.data, ...loadfeed]
    setFeeds({
      ...resp.data,
      has: resp.data.next_page_url !== null,
      data: newData
    })
  }

  function loadFeeds (value) {
    const itens = []
    value.forEach((item, index) => {
      itens[index] = {
        url: `${item.cdn_url}?token=${sessionStorage.getItem('token')}&type=media`,
        type: item.cdn_url.endsWith('.mp4') ? 'video' : 'image',
        duration: '115000',
        nickname: item.nickname,
        avatar: item.avatar_thumbnail_small,
        user_id: item.user_id,
        watermark: item.watermark,
        thumbnail: getThumbnailURL({ thumbnail: item.thumbnail }),
        latest_stories: item.latest_stories,
        is_subscription: item.isSubscription,
        description: item.description
      }
    })
    // console.log(itens)
    return itens
  }

  async function getProducers () {
    const resp = await api.get('/producers/feed?limit=30')
    setProducers({ ...producers, itens: resp.data.sort(() => Math.random() - 0.5) })
  }
  function getMoreFeeds () {
    getFeeds()
    setProducers({ ...producers, current: producers.current < producers.itens.length ? producers.current + 3 : 0 })
  }

  const SugestionProducers = () => {
    // console.log(producers)
    return (
      <Row className='border-top border-bottom pt-3 pb-2 bg-light mb-4'>
        <Col xs={12}>
          <p className="lead font-weight-bold text-primary">
            Sugestões para você...
          </p>
        </Col>
        {
        producers.itens.filter((item, index) => {
          return index >= producers.current && index < producers.current + 3
        }).map((item, index) => (
          <Col xs={4} key={`sugestion-${index}`}>
            <a href={`/${item.nickname}`} className='text-decoration-none'>
              <Avatar url={item.avatar === null ? null : { id: item.id }} className='mx-auto'/>
              <p className='text-center text-primary text-break'><small>@{item.nickname}</small></p>
            </a>
          </Col>
        ))
        }
      </Row>
    )
  }

  return (
    <section>
      <Container className='content Feed'>
        <Row>
          <Col sm={10} md={6} className='mx-auto'>
            <p className='lead mb-1'>{ user.name }, bem vindo(a) ao seu feed!</p>
            <div className='mb-3'><small>Aqui você poderá acompanhar as últimas novidades dos seus produtores assinados!</small></div>
            <Feeds items={feeds.data} />
            <SugestionProducers />
            {feeds.has && <Button className='w-100' onClick={getMoreFeeds}>Ver mais</Button>}
          </Col>
        </Row>
      </Container>
      <LoadingPage show={loading} />
    </section>
  )
}

export default Feed
