import React, { useState, useEffect } from 'react'
import { Container, Button, Form, Card, ListGroup } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import api from '../../services/api'
import Avatar from '../../components/Avatar/index'
import ButtonLoading from '../../components/ButtonLoading'
import { kondutoPage } from '../../services/konduto'
import { LoadingPage } from '../../components/Loadings/index'
import '../../styles/forms.scss'
import './style.scss'

function Search () {
  const history = useHistory()
  const [users, setUsers] = useState({ data: [], has: false, current_page: 0 })
  const [btnloading, setBtnloading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState({ older: '', new: '' })
  useEffect(() => {
    kondutoPage('search')
    searchProducer()
  }, [])

  const searchProducer = () => {
    setBtnloading(true)
    setLoading(true)
    const currentPage = search.older !== search.new ? 1 : users.current_page + 1
    try {
      api.get(`/producers?page=${currentPage}&search=${search.new}`).then((res) => {
        if (res.data.data.length > 0) {
          const newData = search.older !== search.new ? res.data.data : [...users.data, ...res.data.data]
          setSearch({ ...search, older: search.new })
          setUsers({ ...res.data, has: res.data.next_page_url !== null, data: newData })
        }
      }).finally(() => {
        setBtnloading(false)
        setLoading(false)
      })
    } catch (error) {
      setBtnloading(false)
      setLoading(false)
    }
  }

  const handleProfile = (user) => {
    history.push({ pathname: `/${user.nickname}` })
  }
  const handlesearch = (value) => {
    setSearch({ ...search, new: value })
  }
  return (
    <section>
      <Container className={'content'}>
        <Card className={'col-md-7 col-lg-5 mx-auto pt-3'}>
          <Card.Body><Card.Title>Resultados de busca</Card.Title></Card.Body>
          <Card.Body>
            <Form className='my-0'>
              <Form.Group>
                <Form.Label className={'text-muted'}>Procure produtores pelo nome de perfil</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Buscar Produtor'
                  value={search.new}
                  onChange={(event) => handlesearch(event.currentTarget.value)}
                  name='search'
                />
              </Form.Group>
              <Button type='button' className={'w-100'} variant="submit" onClick={searchProducer} disabled={btnloading}>
                <ButtonLoading label='Pesquisar' />
              </Button>
            </Form>
          </Card.Body>
          <Card.Body>
            {users &&
            <Card.Title>
              <small>{`${users.data.length}`} {users.data.length <= 1 ? 'produtor encontrado' : 'produtores encontrados'}</small>
            </Card.Title>
            }
            {users && <ListGroup variant="flush mb-4">
              { users.data.map((item) => (
                <ListGroup.Item key={item.id} onClick={() => { handleProfile(item) }} className={'media'}>
                  <Avatar url={item.avatar === null ? null : item} size={90} className={'mr-3 float-left'}/>
                  <div className="media-body mt-3">
                    <h5 className="mt-0 mb-1">{ item.name_social }</h5>
                    <p>{`@${item.nickname}`}</p>
                  </div>
                </ListGroup.Item>
              ))}
              </ListGroup>
            }
            { users.has && <Button className='w-100' onClick={searchProducer}>Ver mais</Button> }
          </Card.Body>
        </Card>
      </Container>
      <LoadingPage show={loading} />
    </section>
  )
}

export default Search
