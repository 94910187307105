import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Login from '../pages/Authentic/Login'
import Signup from '../pages/Authentic/Signup'
import ForgotPassword from '../pages/Authentic/Forgot'
import RecoverPassword from '../pages/Authentic/changedPassword.jsx'
import Invite from '../pages/Invite/index.jsx'
import InviteLogin from '../pages/Invite/autoLogin.jsx'
import AuthRoutes from './privateRoutes.js'
import PublicRoutes from './publicRoutes.js'
import PublicProfile from '../pages/PublicProfile/index.jsx'
import PaymentForm from '../pages/Payment/index.jsx'
import PaymentLink from '../pages/Payment/link.jsx'
import Producer from '../pages/Producer/Index'
import ProducerEditPerfil from '../pages/Producer/EditPerfil.jsx'
import ProducerEditAvatar from '../pages/Producer/EditAvatar.jsx'
import ProducerEditCover from '../pages/Producer/EditCover.jsx'
import ProducerPaymentForm from '../pages/ProducerPaymentForm/index.jsx'
import MySignatures from '../pages/MySignatures/index.jsx'
import Search from '../pages/Search/index.jsx'
import Validation from '../pages/Validation/index.jsx'
import SendInvitation from '../pages/SendInvitation/index.jsx'
import Billing from '../pages/Billing/index.jsx'
import isAuthenticated from '../services/IsAuthenticated.js'
import Manual from '../pages/guide/index'
import Feed from '../pages/Feed/index'
import NavFooter from '../components/Navbar/Footer'

export default function Routes () {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/invites/:token?/:email?" component={InviteLogin} />
        <PublicRoutes path="/login" component={Login} menu={false} />
        <PublicRoutes path="/signup" component={Signup} menu={false}/>
        <PublicRoutes path="/forgot" component={ForgotPassword} menu={false}/>
        <PublicRoutes path="/recover-password/:token?" component={RecoverPassword} />
        {/* <AuthRoutes path="/" exact component={Validation} /> */}
        <AuthRoutes path="/" exact component={Feed} menu={true} />
        <AuthRoutes path="/profile/:name?" component={PublicProfile} menu={true} />
        <AuthRoutes path="/sendInvitation" component={SendInvitation} />
        <AuthRoutes path="/billing" component={Billing} />
        <AuthRoutes path="/producer" component={Producer}/>
        <AuthRoutes path="/producer-edit-perfil" component={ProducerEditPerfil} />
        <AuthRoutes path="/producer-edit-avatar" component={ProducerEditAvatar}/>
        <AuthRoutes path="/producer-edit-cover" component={ProducerEditCover}/>
        <AuthRoutes path="/invitesAccept/:token?" component={Invite} />
        <AuthRoutes path="/mySignatures" component={MySignatures} />
        <AuthRoutes path="/search" component={Search} />
        {/* <AuthRoutes path="/payment" component={PaymentForm} menu={true} /> */}
        <AuthRoutes path="/link/" component={PaymentLink} menu={true} />
        <AuthRoutes path="/producer-payment" component={ProducerPaymentForm} menu={true} />
        <AuthRoutes path="/manual" component={Manual} menu={true} />
        { isAuthenticated() && <AuthRoutes path="*" component={PublicProfile} menu={true}/>}
        { !isAuthenticated() && <PublicRoutes path="*" component={PublicProfile} menu={true}/>}
      </Switch>
      { isAuthenticated() && <NavFooter />}
    </BrowserRouter>
  )
}
