import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Container, Button, Form, Card, FormControl, InputGroup } from 'react-bootstrap'
import { Validate, fname, fnickname, fbio, fprice, fagree } from '../../utils/FieldsConstrains.js'
import ButtonLoading from '../../components/ButtonLoading'
import { LoadingItens } from '../../components/Loadings'
import api from '../../services/api'
import { currencyFormat } from '../../services/utils'
import { alert, confirm } from 'react-bootstrap-confirmation'
import { kondutoPage, kondutoCustomerId } from '../../services/konduto'
import '../../styles/forms.scss'
import { ErrorSVGIcon } from '@react-md/material-icons'

function EditPerfil () {
  const history = useHistory()
  const initialValues = {
    id: '', name: '', nickname: '', bio: '', currentprice: '', price: '', agree: false
  }
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState(initialValues)
  const [validated, setValidated] = useState(false)
  const [btnloading, setBtnloading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const getUser = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      kondutoCustomerId(user.vindi_customer)
      setValues({ id: user.id, name: user.name_social, nickname: user.nickname || '', bio: user.bio || '', currentprice: user.price, price: '', agree: true })
      setErrors({ ...errors, agree: '' })
      setIsEdit(true)
    }
  }

  useEffect(() => {
    kondutoPage('producer-info')
    getUser()
  }, [])

  const handlerValidaNickname = async (event) => {
    const evento = event.target
    evento.setCustomValidity('Buscando nome...')
    setErrors({ ...errors, nickname: 'buscando' })
    const resp = await api.post(`/users/nickname/${evento.value}`)
    if (resp.data.available) {
      evento.setCustomValidity('')
      setErrors({ ...errors, nickname: '' })
    } else {
      evento.setCustomValidity('Nome já existente!')
      setErrors({ ...errors, nickname: 'Nome já existente!' })
    }
  }
  const handlerChange = (event) => {
    // event.preventDefault()
    // event.stopPropagation()
    const { name, msg, vformated } = Validate(event)
    setValues({ ...values, [name]: vformated })
    setErrors({ ...errors, [name]: msg })

    setValidated(true)
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const { msgErros, isValidity } = Validate(event)
    setErrors(msgErros)

    if (isValidity) {
      saveProfile()
    }

    setValidated(true)
  }
  async function saveProfile () {
    // console.log(values.price)
    const params = {
      id: values.id,
      name_social: values.name,
      nickname: values.nickname,
      bio: values.bio,
      price: values.price !== '' ? values.price : values.currentprice,
      terms: isEdit ? true : values.agree
    }

    try {
      setBtnloading(true)
      await api.put('users/producer-info', params)
      history.push('/user')
      window.location.reload()
      alert('Seus dados foram atualizados com sucesso', { okButtonStyle: 'success' })
    } catch (error) {
      alert('Falha ao buscar seus dados', { okButtonStyle: 'danger' })
    }
    setBtnloading(false)
  }

  return (
    <section>
      <Container className={'content'}>
        <Card className={'col-md-7 col-lg-5 mx-auto pt-3'}>
          <Card.Body>
            <Card.Title>Perfil de produtor</Card.Title>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label className={'text-muted'}>Nome de Exibição</Form.Label>
                <Form.Control
                  {...fname.fields}
                  defaultValue={values.name}
                  placeholder='Ex. Chloe Moretz'
                  onChange={handlerChange}
                />
                <Form.Control.Feedback type={'invalid'}>{errors.name}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label className={'text-muted'}>Nome de Usuário (url de acesso)</Form.Label>
                <FormControl
                  {...fnickname.fields}
                  defaultValue={values.nickname}
                  placeholder='chloemoretz'
                  onChange={handlerChange}
                  onBlur={handlerValidaNickname}
                />
                { errors.nickname === 'buscando' && <LoadingItens className={'my-2 d-flex justify-content-center'} label={'buscando por nome...'}/>}
                <Form.Control.Feedback type={'invalid'}> {errors.nickname !== 'buscando' && errors.nickname} </Form.Control.Feedback>
                <Form.Label className={'text-muted mt-2'}>
                  Sua página para divulgação do seu perfil é:
                  <div>
                    {`http://tokplace.com/${values.nickname}`}
                  </div>
                </Form.Label>
              </Form.Group>
              <Form.Group>
                <Form.Label className={'text-muted'}>Bio</Form.Label>
                <FormControl
                  {...fbio.fields}
                  placeholder='Ex. Chloe Moretz'
                  defaultValue={values.bio}
                  onChange={handlerChange}
                />
                <Form.Control.Feedback type={'invalid'}> {errors.bio} </Form.Control.Feedback>
              </Form.Group>

              <Card.Title>Assinatura do seu conteúdo<br/><small>Seu valor atual é: {currencyFormat(values.currentprice)}</small></Card.Title>

              <Form.Group>
                <Form.Label className={'text-muted'}><small>Valor mensal para assinar seu perfil (mínimo R$9,99)</small></Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text className={'bg-success text-light'}>R$</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    {...fprice.fields}
                    value={values.price}
                    placeholder='Insira um novo valor.'
                    onChange={handlerChange}
                  />
                </InputGroup>
                <Form.Control.Feedback type={'invalid'}> {errors.price} </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Row>
                  {/* <Form.Label className={'w-100 mb-0'}>Termos e Política de privacidade.</Form.Label> */}
                  <Link exact="true" to="//www.tokplace.com/termos-de-uso.html" target="_blank" className="col-12 text-primary text-decoration-none w-100">
                    Termos de uso
                  </Link>
                  <Link exact="true" to="//www.tokplace.com/termos-de-compra.html" target="_blank" className="col-12 text-primary text-decoration-none w-100">
                    Termos de compra
                  </Link>
                  <Link exact="true" to="//www.tokplace.com/politica-privacidade.html" target="_blank" className="col-12 text-primary text-decoration-none w-100">
                    Política de privacidade
                  </Link>
                </Form.Row>
              </Form.Group>
              <Form.Group>
                <Form.Label className={'text-muted'}></Form.Label>
                <Form.Check
                  {...fagree.fields}
                  defaultValue={values.agree}
                  onChange={handlerChange}
                />
                <Form.Control.Feedback type={'invalid'}> {errors.agree} </Form.Control.Feedback>
              </Form.Group>
              <Button type={'submit'} className={'w-100'} variant="submit">
                <ButtonLoading label='Salvar Perfil' active={btnloading} />
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </section>
  )
}
export default EditPerfil
