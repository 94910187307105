import React, { useState, useEffect } from 'react'
import { LogoSymbolTriadi } from '../Logo/index'
import { Modal, ProgressBar } from 'react-bootstrap'
import './style.scss'

export const LoadingPage = (props) => {
  const label = props.label || 'carregando...'
  return (
    <Modal show={props.show} size="lg" className={'LoadingPage text-center'} aria-labelledby="contained-modal-title-vcenter" centered>
      <div className='logo'>
        <LogoSymbolTriadi />
      </div>
      <h4 className='font-weight-light txt-loading'>{label}</h4>
      {props.percent &&
        <ProgressBar animated now={props.percent} label={`${props.percent}%`} />
      }
    </Modal>
  )
}

export const LoadingItens = (props) => {
  const label = props.label || 'Carregando...'
  return (
    <div className={`${props.className} LoadingItens`}>
      <LogoSymbolTriadi /> <span className='font-weight-light'>{label}</span>
    </div>
  )
}
