import React, { useState } from 'react'
import { Container, Button, Form, Card } from 'react-bootstrap'
import ButtonLoading from '../../components/ButtonLoading'
import { alert, confirm } from 'react-bootstrap-confirmation'
import { Link, useHistory } from 'react-router-dom'
import { MdChevronLeft } from 'react-icons/md'
import logoImg from '../../assets/logo/tokplace_logo.svg'
import api from '../../services/api'
import { Validate, femail } from '../../utils/FieldsConstrains'
import '../../styles/forms.scss'

function ForgotPassword () {
  const history = useHistory()
  const initialValues = {
    email: ''
  }
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState(initialValues)
  const [validated, setValidated] = useState(false)
  const [btnloading, setBtnloading] = useState(false)

  const handlerChange = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const { name, msg, vformated } = Validate(event)
    setValues({ ...values, [name]: vformated })
    setErrors({ ...errors, [name]: msg })

    setValidated(true)
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const { msgErros, isValidity } = Validate(event)
    setErrors(msgErros)

    if (isValidity) {
      sendEmail()
    }

    setValidated(true)
  }
  async function sendEmail () {
    setBtnloading(true)
    const param = {
      email: values.email
    }
    try {
      await api.post('/forgot-password', param).then(res => {
        history.push('/login')
        alert('Enviamos um link de recuperação para o seu email.', { okButtonStyle: 'success' })
        setBtnloading(false)
      })
    } catch (error) {
      alert('Por favor, informe o e-mail válido.', { okButtonStyle: 'danger' })
      setBtnloading(false)
    }
  }

  return (
    <Container className={'form'}>
      <Card className={'col-md-7 col-lg-5 mx-auto pt-3'}>
        <Link to="/login">
          <MdChevronLeft size={40} color="#000" />
        </Link>
        <Card.Img variant="top" src={logoImg} className={'mx-auto'} alt="tokplace" />
        <Card.Body>
          <Card.Title>Recuperar Senha</Card.Title>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label className={'text-muted'}>Esqueceu sua senha? Enviaremos um link para seu e-mail cadastrado:</Form.Label>
              <Form.Control
                {...femail.fields}
                value={values.email}
                onChange={handlerChange}
              />
              <Form.Control.Feedback type={'invalid'}>{errors.email}</Form.Control.Feedback>
            </Form.Group>
            <Button type={'submit'} className={'w-100'} variant="submit" disabled={btnloading}>
              <ButtonLoading label='Cadastrar' active={btnloading} />
            </Button>
          </Form>
          <Form.Group>
            <Form.Label className={'w-100 mb-0'}>Já possui uma conta?</Form.Label>
            <Link to="/login" className="text-primary text-decoration-none">
              Faça Login
            </Link>
          </Form.Group>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default ForgotPassword
