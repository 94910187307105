import React, { useState, useEffect } from 'react'
import { Container, Button, Form, Card, InputGroup, FormControl, Col } from 'react-bootstrap'
import ButtonLoading from '../../components/ButtonLoading'
import { alert, confirm } from 'react-bootstrap-confirmation'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import ListStates from '../../utils/state'
import { MdChevronLeft } from 'react-icons/md'
import { FiPhone } from 'react-icons/fi'
import logoImg from '../../assets/logo/tokplace_logo.svg'
import { Link, useHistory } from 'react-router-dom'
import api from '../../services/api'
import { locationFromPostalCode } from '../../utils/location'
import { DateFormater } from '../../utils/dateFormater'
import { LoadingItens } from '../../components/Loadings'
import { kondutoPage } from '../../services/konduto'
import {
  Validate,
  fname,
  ftelphone,
  femail,
  fcpf,
  fbirthday,
  fisolder,
  fcep,
  faddress,
  faddressnumber,
  faddresscomplement,
  faddressdistrict,
  faddresscity,
  faddressstate,
  faddresscountry,
  fpassword,
  fpolicy
} from '../../utils/FieldsConstrains.js'
import '../../styles/forms.scss'

function Signup (query) {
  const history = useHistory()
  const initialValues = {
    email: '',
    name: '',
    telphonetype: 'selecione',
    telphoneddd: '',
    telphone: '',
    cpf: '',
    birthday: '',
    isolder: '',
    cep: '',
    address: '',
    addressnumber: '',
    addresscomplement: '',
    addressdistrict: '',
    addresscity: '',
    faddressstate: '',
    addresscountry: 'BR',
    password: '',
    passwordconfirm: '',
    policy: ''
  }
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState(initialValues)
  const [validated, setValidated] = useState(false)
  const [eyes, setEyes] = useState('password')
  const [btnloading, setBtnloading] = useState(false)
  const [loadingBuscaCep, setLoadingBuscaCep] = useState(false)
  const [isInvite, setIsInvite] = useState(localStorage.getItem('emailInvite'))

  useEffect(() => {
    kondutoPage('signup')
    if (isInvite) {
      setValues({ ...values, email: isInvite })
    }
  }, [])

  const OptionState = () => ListStates.map((item, index) => {
    return <option key={`state-${index}`} value={item.sigla}>{item.nome_pais}</option>
  })

  function handlePasswordEye (event) {
    const openeye = document.querySelector('#password').attributes.type.value
    if (openeye === 'password') {
      setEyes('text')
    } else {
      setEyes('password')
    }
  }
  const handleCepInputAutomatic = async (event) => {
    const cep = values.cep
    if (cep && errors.cep === '') {
      setLoadingBuscaCep(true)
      try {
        const response = await locationFromPostalCode(cep)
        // console.log(response)
        const data = {
          municipio: response.city,
          address: response.address,
          bairro: response.district,
          uf: response.state
        }
        if (response) {
          setLoadingBuscaCep(false)
        }
        setValues({
          ...values,
          addressstate: data.uf,
          addresscity: data.municipio,
          addressdistrict: data.bairro,
          address: data.address
        })
        setErrors({
          ...errors,
          addressstate: data.uf,
          addresscity: undefined,
          addressdistrict: undefined,
          address: undefined
        })
      } catch (e) {
        alert(e.message, { okButtonStyle: 'danger' })
      }
    }
  }
  // events for validation
  const handlerChange = (event) => {
    if (event.target.name === 'isolder') {
      event.target.value = +event.target.checked
    }
    // event.preventDefault()
    event.stopPropagation()
    const { name, msg, vformated } = Validate(event)
    setValues({ ...values, [name]: vformated })
    setErrors({ ...errors, [name]: msg })

    setValidated(true)
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const { msgErros, isValidity } = Validate(event)
    setErrors(msgErros)

    if (isValidity) {
      startRegister()
    }

    setValidated(true)
  }
  async function startRegister () {
    setBtnloading(true)
    const param = {
      name: values.name,
      name_social: values.name,
      email: values.email,
      telphone_type: values.telphonetype,
      telphone_ddd: values.telphoneddd,
      telphone: values.telphone,
      registry_code: values.cpf,
      password: values.password,
      password_confirmation: values.passwordconfirm,
      birth_date: values.birthday,
      is_older: values.isolder,
      postcode: values.cep,
      country: values.addresscountry,
      state: values.addressstate,
      city: values.addresscity,
      district: values.addressdistrict,
      street: values.address,
      number: values.addressnumber,
      complement: values.addresscomplement
    }
    // console.log(param)
    try {
      await api.post('/register', param)
      // await loginInvites()
      alert('Seu cadastro foi efetuado com sucesso!', { okButtonStyle: 'success' })
      setBtnloading(false)
      if (isInvite) {
        history.push({ pathname: '/invites' })
      } else {
        history.push({ pathname: '/login' })
      }
    } catch (error) {
      alert('Preencha todos os campos obrigatórios', { okButtonStyle: 'danger' })
      setBtnloading(false)
    }
  }

  // E-mail Invites
  const hasEmail = () => {
    return ![undefined, null, '', 'null'].includes(localStorage.getItem('emailInvite'))
  }
  const MensageEmailInvite = () => {
    return (
      <Card.Body>
        <Card.Title>Seja Bem-vindo(a)!</Card.Title>
        <Card.Text>
          Você recebeu um convite para ser produtor no acesso antecipado à nossa plataforma. Aproveite!
        </Card.Text>
      </Card.Body>
    )
  }

  return (
    <Container className={'form'}>
      <Card className={'col-md-7 col-lg-5 mx-auto pt-3'}>
        <Link to="/login">
          <MdChevronLeft size={40} color="#000" />
        </Link>
        <Card.Img variant="top" src={logoImg} className={'mx-auto'} alt="tokplace" />
        { isInvite && <MensageEmailInvite/> }
        <Card.Body>
          <Card.Title>Cadastro</Card.Title>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label className={'text-muted'}>E-mail</Form.Label>
              <Form.Control
                {...femail.fields}
                value={values.email}
                onChange={handlerChange}
              />
              <Form.Control.Feedback type={'invalid'}>{errors.email}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label className={'text-muted'}>Nome completo</Form.Label>
              <Form.Control
                {...fname.fields}
                placeholder='John Doe'
                value={values.name}
                onChange={handlerChange}
              />
              <Form.Control.Feedback type={'invalid'}>{errors.name}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label className={'text-muted'}>CPF</Form.Label>
              <Form.Control
                {...fcpf.fields}
                value={values.cpf}
                onChange={handlerChange}
              />
              <Form.Control.Feedback type={'invalid'}>{errors.cpf}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label className={'text-muted'}>Telefone</Form.Label>
              <Form.Control
                {...ftelphone.fieldstype}
                defaultValue={values.telphonetype}
                onChange={handlerChange}
              >
                {ftelphone.fieldstypeOption.map((item) => {
                  return <option key={item.value} value={item.value}>{item.text}</option>
                })}
              </Form.Control>
              <Form.Control.Feedback type={'invalid'}>{errors.telphonetype}</Form.Control.Feedback>
              <InputGroup className='mt-4'>
                <Form.Control
                  className='col-4'
                  {...ftelphone.fieldsddd}
                  value={values.telphoneddd}
                  onChange={handlerChange}
                />
                <Form.Control
                  isInvalid
                  {...ftelphone.fields}
                  value={values.telphone}
                  onChange={handlerChange}
                />
              </InputGroup>
              <Form.Control.Feedback type={'invalid'} className='d-block'>{errors.telphoneddd}</Form.Control.Feedback>
              <Form.Control.Feedback type={'invalid'} className='d-block'>{errors.telphone}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label className={'text-muted'}>Data de Nascimento</Form.Label>
              <Form.Control
                {...fbirthday.fields}
                value={values.birthday}
                onChange={handlerChange}
              />
              <Form.Control.Feedback type={'invalid'}>{errors.birthday}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Check
                {...fisolder.fields}
                feedback={errors.isolder}
                isInvalid={true}
                onChange={handlerChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className={'text-muted'}>Cep {loadingBuscaCep && <LoadingItens label={'buscando cep...'}/>}</Form.Label>
              <InputGroup className="mb-3" hasValidation>
                <Form.Control
                  {...fcep.fields}
                  value={values.cep}
                  onChange={handlerChange}
                  onBlur={(e) => handleCepInputAutomatic(e)}
                />
                <InputGroup.Append>
                  <Link
                    to={{ pathname: 'https://buscacepinter.correios.com.br/app/endereco/index.php' }}
                    target="_blank"
                    className={'btn btn-primary pt-4'}
                  >
                    Não sei meu CEP
                  </Link>
                </InputGroup.Append>
                <Form.Control.Feedback type={'invalid'}>{errors.cep}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label className={'text-muted'}>Logradouro</Form.Label>
              <Form.Control
                {...faddress.fields}
                value={values.address}
                onChange={handlerChange}
              />
              <Form.Control.Feedback type={'invalid'}>{errors.address}</Form.Control.Feedback>
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className={'text-muted'}>Número</Form.Label>
                <Form.Control
                  {...faddressnumber.fields}
                  value={values.addressnumber}
                  onChange={handlerChange}
                />
                <Form.Control.Feedback type={'invalid'}>{errors.addressnumber}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className={'text-muted'}>Complemento</Form.Label>
                <Form.Control
                  {...faddresscomplement.fields}
                  value={values.addresscomplement}
                  onChange={handlerChange}
                />
                <Form.Control.Feedback type={'invalid'}>{errors.addresscomplement}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Group>
              <Form.Label className={'text-muted'}>Bairro</Form.Label>
              <Form.Control
                {...faddressdistrict.fields}
                value={values.addressdistrict}
                onChange={handlerChange}
              />
              <Form.Control.Feedback type={'invalid'}>{errors.addressdistrict}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label className={'text-muted'}>Município</Form.Label>
              <Form.Control
                {...faddresscity.fields}
                value={values.addresscity}
                onChange={handlerChange}
              />
              <Form.Control.Feedback type={'invalid'}>{errors.addresscity}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label className={'text-muted'}>UF</Form.Label>
              <Form.Control
                {...faddressstate.fields}
                defaultValue={values.addressstate}
                onChange={(e) => handlerChange(e)}
              >
              </Form.Control>
              <Form.Control.Feedback type={'invalid'}>{errors.addressstate}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>País</Form.Label>
              <Form.Control
                as="select"
                {...faddresscountry.fields}
                value={values.addresscountry}
                onChange={handlerChange}
                custom
              >
                <OptionState/>
              </Form.Control>
              <Form.Control.Feedback type={'invalid'}>{errors.addresscountry}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label className={'text-muted'}>Senha</Form.Label>
              <InputGroup hasValidation>
                <FormControl
                {...fpassword.fields}
                  type={eyes}
                  value={values.password}
                  onChange={handlerChange}
                />
                <InputGroup.Append>
                  <InputGroup.Text
                    className={'view-password border-left-0 bg-transparent'}
                    onClick={handlePasswordEye}
                  >
                    { eyes === 'password' ? <AiFillEye/> : <AiFillEyeInvisible/> }
                  </InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type={'invalid'}> {errors.password} </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label className={'text-muted'}>Confirmar Senha</Form.Label>
              <InputGroup hasValidation>
                <FormControl
                  {...fpassword.fieldsconfirm}
                  type={eyes}
                  value={values.passwordconfirm}
                  onChange={handlerChange}
                />
                <InputGroup.Append>
                  <InputGroup.Text
                    className={'view-password border-left-0 bg-transparent'}
                    onClick={handlePasswordEye}
                  >
                    { eyes === 'password' ? <AiFillEye/> : <AiFillEyeInvisible/> }
                  </InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type={'invalid'}> {errors.passwordconfirm} </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Row>
                {/* <Form.Label className={'w-100 mb-0'}>Termos e Política de privacidade.</Form.Label> */}
                <Link exact="true" to="//www.tokplace.com/termos-de-uso.html" target="_blank" className="col-12 text-primary text-decoration-none w-100">
                  Termos de uso
                </Link>
                <Link exact="true" to="//www.tokplace.com/termos-de-compra.html" target="_blank" className="col-12 text-primary text-decoration-none w-100">
                  Termos de compra
                </Link>
                <Link exact="true" to="//www.tokplace.com/politica-privacidade.html" target="_blank" className="col-12 text-primary text-decoration-none w-100">
                  Política de privacidade
                </Link>
              </Form.Row>
            </Form.Group>
            <Form.Group>
              <Form.Check
                {...fpolicy.fields}
                label="Declaro estar de acordo com os termos de uso, termos de compra e a política de privacidade."
                feedback={errors.policy}
                isInvalid={true}
                onChange={handlerChange}
              />
            </Form.Group>
            <Button type={'submit'} className={'w-100'} variant="submit" disabled={btnloading}>
              <ButtonLoading label='Cadastrar' active={btnloading} />
            </Button>
          </Form>
          <Form.Group>
            <Form.Label className={'w-100 mb-0'}>Já possui uma conta?</Form.Label>
            <Link to="/login" className="text-primary text-decoration-none">
              Faça Login
            </Link>
          </Form.Group>
        </Card.Body>
      </Card>
    </Container>
  )
}
export default Signup
