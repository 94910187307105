import axios from 'axios'

const getToken = () => {
  return sessionStorage.getItem('token')
}
axios.defaults.baseURL = process.env.REACT_APP_API_URL
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json'
})
api.interceptors.request.use((config) => {
  const url = config.url
  if (url === '/login') {
    config.headers['Application-Identifier'] = 'tokplace'
    // config.headers.credentials = 'same-origin'
  }
  config.headers.Authorization = `Bearer ${getToken()}`
  return config
})

export default api
