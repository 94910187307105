import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Container, Button, Row, Tab, Nav, Card, Col } from 'react-bootstrap'
import { LoadingPage } from '../../components/Loadings'
import { MdRestore } from 'react-icons/md'
import api from '../../services/api'
import Authenticated from '../../services/IsAuthenticated'
import getProducerStories from '../../services/getProducerStories'
import { Gallery, Stories } from '../../components/Gallery'
// import getProducerGallery from '../../services/getProducerGallery'
import { currencyFormat, getCoverURL } from '../../services/utils'
import PaymentBrands from '../../components/PaymentBrands'
import Cover from '../../components/Cover'
import Avatar from '../../components/Avatar'
import { kondutoPage } from '../../services/konduto'
import './styles.scss'

function PublicProfile () {
  const history = useHistory()
  const [isAuthenticated, setIsAuthenticated] = useState(Authenticated())
  const [watermark, setWatermark] = useState(false)
  const [stories, setStories] = useState()
  const [producer, setProducer] = useState(false)
  const [user, setUser] = useState({ name: '' })
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [plans, setPlans] = useState([])
  const [loading, setLoading] = useState(false)
  const [stats, setStats] = useState({
    posts: 0,
    videos: 0,
    photos: 0
  })

  function getLocalStorage (key) {
    return JSON.parse(localStorage.getItem(key))
  }
  function setLocalStorage (key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  }
  function setWatchedPost (id) {
    const lastPosts = (getLocalStorage('lastPosts')).map(item => {
      if (item.user_id === id) {
        item.newdate = false
      }
      return item
    })
    // console.log('lastPosts', lastPosts)
    setLocalStorage('lastPosts', lastPosts)
  }

  useEffect(() => {
    kondutoPage('public-profile')
    getProducer()
    getUser()
  }, [])
  useEffect(() => {
    if (producer !== null) {
      if (producer.is_subscribed === true || producer.is_subscribed_import === true) {
        setIsSubscribed(true)
      } else {
        setIsSubscribed(false)
      }
    }
  }, [producer])

  async function getUser () {
    const user = await JSON.parse(localStorage.getItem('user'))
    setUser(user)
    setWatermark(user.watermark)
    // console.log(user)
  }
  const getProducer = async () => {
    setLoading(true)
    const url = new URL(window.location.href)
    const user = url.pathname.substring(1)
    if (user) {
      try {
        const { data } = await api.get(`/${user}/info`)
        // console.log(data)
        setPlans(data.plans)
        setStats(data.stats)
        setProducer(data)
      } catch {
        setProducer(false)
      }
      setLoading(false)
    }
  }
  const doLoadStories = (producer) => {
    // console.log(producer)
    if (isAuthenticated && (producer.is_subscribed || producer.is_subscribed_import)) {
      setLoading(true)
      getProducerStories(producer.id).then(stories => {
        const token = sessionStorage.getItem('token')
        const items = stories.data.map(story => {
          const url = `${story.cdn_url}?token=${token}&type=media`
          const type = story.cdn_url.endsWith('.mp4') ? 'video' : 'image'
          return { url, type, header: { heading: story.description } }
        })
        if (items.length > 0) {
          setWatchedPost(producer.id)
          setStories(items)
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
    }
  }

  // compra por cartão desativado temporariamente.
  function handleBuyPlans (plan) {
    localStorage.setItem('payment', JSON.stringify({ plan, producer, context: 'credit_card' }))
    isAuthenticated ? history.push('/payment') : history.push('/login')
  }
  function handleBuyPix () {
    // const plan = plans.filter(plan => plan.id === process.env.REACT_APP_PLAN_ID_1M)[0]
    plans[2].id = process.env.REACT_APP_PLAN_ID_1M
    const plan = plans[2]
    localStorage.setItem('payment', JSON.stringify({ plan, producer, context: 'pix' }))
    if (plan) {
      isAuthenticated ? history.push('/payment') : history.push('/login')
    }
  }

  const HowItWorks = () => (
    <div>
      <h5>Como funciona...</h5>
      <ul className="list-unstyled">
        <li>
          <ol className='mb-3'>
            <li>
              Escolha a forma de pagamento que desejar e clique em “Assinar”
            </li>
            <li>
              Crie o seu cadastro preenchendo corretamente os seus dados
            </li>
            <li>
              Finalize o seu pagamento
            </li>
          </ol>
        </li>
        <li>Ao concluir seu pagamento, sua assinatura já estará disponível no menu Assinaturas.</li>
        <li>Você poderá buscar por outros produtores de conteúdo e assinar quantos quiser!</li>
        <li>Aproveite a TokPlace e divirta-se!</li>
      </ul>
    </div>
  )
  const ProducerStats = () => (
    <Card className={'Stats shadow mb-4'}>
      <Card.Body>
        <Card.Subtitle className='mb-2'>{producer && '@' + producer.nickname} nos últimos 30 dias</Card.Subtitle>
        <Row>
          <Col><MdRestore size={40} /></Col>
          <Col>
            <p className='h3 mb-0'>{ stats.posts }</p>
            <p className='mb-0'>Posts</p>
          </Col>
          <Col>
            <p className='h3 mb-0'>{ stats.photos }</p>
            <p className='mb-0'>Fotos</p>
          </Col>
          <Col>
            <p className='h3 mb-0'>{ stats.videos }</p>
            <p className='mb-0'>Videos</p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
  const ProducerPlans = () => {
    return (
      <Card className={'Stats shadow mb-4'}>
        <Card.Body>
          <Tab.Container id="left-tabs-example" defaultActiveKey='Pix'>
            <Row>
              <Col md={12}>
                {/* <Nav className="btn-group">
                  {plans.map((plan, index) => (
                    <Nav.Link key={index} eventKey={plan.name} className={'btn btn-primary'}>{plan.name}</Nav.Link>
                  ))}
                </Nav> */}
                {/* <Tab.Content>
                  {plans.map((plan, index) => (
                    <Tab.Pane key={index} eventKey={plan.name}>
                      {plan.name !== 'Pix'
                        ? (
                          <div className='mt-3'>
                            <p className={'mb-0'}>Pagamento de {currencyFormat(plan.total)}</p>
                            {plan.name === '3 meses' && <p className={'mb-0'}>Plano: Recorrência trimestral 10% de desconto (cobrado automaticamente no cartão de crédito a cada 3 meses, até que seja solicitado o cancelamento)</p>}
                            {plan.name === '3 meses' && <Button onClick={() => { handleBuyPlans(plan) }} className={'btn w-100 btn-primary my-2'}>Assinar por {currencyFormat(plan.total)}</Button>}
                            {plan.name === 'Mensal' && <p className={'mb-0'}>Plano: Recorrência mensal (cobrado automaticamente no cartão de crédito a cada 30 dias, até que seja solicitado o cancelamento)</p>}
                            {plan.name === 'Mensal' && <Button onClick={() => { handleBuyPlans(plan) }} className={'btn w-100 btn-primary my-2'}>Assinar por {currencyFormat(plan.total)}</Button> }
                          </div>
                          )
                        : (
                          <div className='mt-3'>
                            <p className={'mb-0'}>Pagamento de {currencyFormat(plan.total)}</p>
                            <p className={'mb-0'}>Plano: 30 dias de acesso (sem recorrência)</p>
                            <p className='mb-2'>Conteúdo exclusivo TokPlace</p>
                            <Button onClick={() => { handleBuyPix() }} className={'btn w-100 btn-primary my-2'}>Assinar por {currencyFormat(plan.total)}</Button>
                          </div>
                          )
                      }
                    </Tab.Pane>
                  ))}
                </Tab.Content> */}
              </Col>
            </Row>
          </Tab.Container>
          <div className={'mb-4'}><PaymentBrands/></div>
          <HowItWorks/>
        </Card.Body>
      </Card>
    )
  }
  const ProducerProfile = () => {
    return (
      <Row>
        { !isSubscribed &&
          <Col md={6} className={'mx-auto border-bottom pb-2 mt-3 mb-3'}>
            <p className="text-center px-5 mb-4">Tenha acesso <b>exclusivo</b> ao perfil TokPlace de {producer.nickname}</p>
            {producer.bio && <p className="mb-4">{producer.bio}</p>}
            <ProducerStats/>
            {!producer.is_payment_blocked && <ProducerPlans/>}
            {!isAuthenticated &&
              <div>
                <div>Já possui uma conta?</div>
                <Link to="/login">
                  <div>Faça Login</div>
                </Link>
              </div>
            }
          </Col>
        }
        { isSubscribed &&
          <>
            <Col md={6} className={'mx-auto pb-2 mt-3 mb-3'}>
              <p className="mb-0 font-weight-bold">{producer.name_social}</p>
              {producer.bio && <p className="mb-0">{producer.bio}</p>}
              {producer.nickname && <p className={'text-primary'}>tokplace.com/{producer.nickname}</p>}
              <h4 className={'mt-4 py-3 display-5 font-weight-light border-top border-bottom'}>POSTAGENS EXCLUSIVAS</h4>
            </Col>
            <Col md={12}>
              { producer.id && <Gallery url={`/producers/${producer.id}/stories`} /> }
              <Stories stories={stories} watermark={watermark} setStories={setStories}/>
            </Col>
          </>
        }
      </Row>
    )
  }

  return (
    <section>
      <Container className={'PublicProfile bg-light'}>
        <div className='row'><Cover url={ producer.cover ? getCoverURL(producer) : null } className={ !producer.cover && 'mb-5' }/></div>
        <Row>
          <Col className={'mb-3'}>
            {producer && <Avatar url={producer.avatar === null ? null : producer}
              size={90}
              className={'mx-auto'}
              onClick={() => { doLoadStories(producer) }}
              lastPost={producer.latest_stories}
            />}
            {!producer && <Avatar size={90} className={'mx-auto'} />}
          </Col>
        </Row>
        {producer ? <ProducerProfile/> : <div className={'text-center py-4'}>{loading ? 'Buscando produtor' : 'Produtor não encontrado'}</div>}
        <LoadingPage show={loading}/>
      </Container>
    </section>
  )
}

export default PublicProfile
