import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Row, Col, Button } from 'react-bootstrap'
import { LogoInline } from '../Logo/index'
import { MdClose, MdFastForward } from 'react-icons/md'
import { BsPlayFill, BsPauseCircle } from 'react-icons/bs'
import Storie from 'react-insta-stories'
import Avatar from '../Avatar/index'
import getProducerStories from '../../services/getProducerStories'
import getProducerGallery from '../../services/getProducerGallery'
import ButtonLoading from '../ButtonLoading'
import { LoadingPage } from '../Loadings'
import watermarkSafety from '../../assets/background/watermark.png'
import './style.scss'

function getLocalStorage (key) {
  return JSON.parse(localStorage.getItem(key))
}
function setLocalStorage (key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}
function setWatchedPost (id) {
  const lastPosts = (getLocalStorage('lastPosts')).map(item => {
    if (item.user_id === id) {
      item.newdate = false
    }
    return item
  })
  // console.log('lastPosts', lastPosts)
  setLocalStorage('lastPosts', lastPosts)
}

const Watermarks = (props) => {
  const [user, setUser] = useState({ name: '' })
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')))
  }, [])
  // console.log('Watermarks', props.active)
  const text = `${user.name} - ${user.registry_code} - `
  return (
    <div className='Watermark w-100'>
      <img src={watermarkSafety} className='rounded img-fluid w-100'/>
      { props.active === 1 && <p>{text.repeat(160)}</p> }
    </div>
  )
}

export const Gallery = (props) => {
  const interceptRef = useRef(null)
  const [gallery, setGallery] = useState({ data: [], paginate: { next_page_url: '' } })
  const [stories, setStories] = useState()
  const [watermark, setWatermark] = useState(false)
  const token = sessionStorage.getItem('token')
  // const count = 0
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0
  }
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        if (gallery.paginate.next_page_url !== null) {
          openGallery((gallery.paginate.next_page_url).replace(process.env.REACT_APP_API_URL, ''))
        }
      }
    }, options)

    if (interceptRef.current) {
      observer.observe(interceptRef.current)
    }

    return () => {
      if (interceptRef.current) {
        observer.unobserve(interceptRef.current)
      } else {
        observer.disconnect()
      }
    }
  }, [gallery])

  function openGallery (url = null) {
    getProducerGallery(url || props.url, 45).then(resp => {
      // console.log('resp:', resp)
      setGallery({ data: [...gallery.data, ...resp.data], paginate: resp })
    })
  }
  function formatStories (item) {
    return {
      url: `${item.cdn_url}?token=${token}&type=media`,
      type: item.cdn_url.endsWith('.mp4') ? 'video' : 'image',
      duration: '150000',
      format: item.resolution_x > item.resolution_y ? 'landscape' : 'portrait',
      header: {
        heading: item.description
      }
    }
  }

  const handlerOpenStories = (event) => {
    // console.log('handlerOpenStories', event)
    setWatermark(event.watermark)
    setStories([formatStories(event)])
    const body = document.querySelector('body')
    body.style.overflow = 'hidden'
  }
  const handlerCloseStory = (event) => {
    // console.log('handlerCloseStory')
    setStories()
    const body = document.querySelector('body')
    body.style.overflow = 'inherit'
  }

  return (
    <>
      <Row className={'Gallery px-1 px-sm-2'}>
        { gallery.data.length > 0 && gallery.data.map((item, index) => (
          <Col key={'gallery-' + index} xs={4} md={4} lg={4} className='px-1'>
            <div
              className={'box p-0 p-sm-2 mb-2 mx-0 mt-0 rounded-lg'}
              onClick={() => handlerOpenStories(item)}
              style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}/stories/${item.thumbnail}?token=${token}&type=thumbnail)` }}
            >
              <Watermarks active={item.watermark}/>
            </div>
          </Col>
        ))
        }
        { gallery.paginate.next_page_url !== null &&
        <Button className='col-12 p-2' variant='light' ref={interceptRef}>
          <ButtonLoading active={true}/>
        </Button>
        }
      </Row>
      { stories &&
        <Row className={'Gallery'}>
          <div className={'Stories bg-dark text-white text-right'}>
            <nav className="navbar navbar-dark bg-dark">
              <a className="navbar-brand" onClick={handlerCloseStory} style={{ cursor: 'pointer' }}><LogoInline /></a>
              <MdClose size={25} onClick={handlerCloseStory} className={'btn-close m-2'}/>
            </nav>
            { stories[0].type === 'video'
              ? <VideoPlayer source={stories[0]} watermark={watermark} onVideoEnd={handlerCloseStory}/>
              : <>
                <Watermarks active={watermark} />
                <Storie stories={stories} onStoryEnd={handlerCloseStory} width='100vw' height='100%'/>
              </>
            }
          </div>
        </Row>
      }
    </>
  )
}

export const Stories = (props) => {
  const handlerOpenStory = () => {
    const body = document.querySelector('body')
    body.style.overflow = 'hidden'
  }

  const handlerCloseStory = () => {
    const body = document.querySelector('body')
    body.style.overflow = 'inherit'
    props.setStories()
  }

  return (
    <Row className={'Gallery'}>
      { props.stories &&
      <div className={'Stories bg-dark text-white text-right'}>
        <nav className="navbar navbar-dark bg-dark">
          <a className="navbar-brand" onClick={handlerCloseStory} style={{ cursor: 'pointer' }}><LogoInline /></a>
          <MdClose size={25} onClick={handlerCloseStory} className={'btn-close m-2'}/>
        </nav>
        <Watermarks active={+props.watermark} />
        <Storie stories={props.stories} onAllStoriesEnd={handlerCloseStory} onStoryStart={handlerOpenStory} width='100vw' height='100%'/>
      </div>
      }
    </Row>
  )
}

export const Feeds = (props) => {
  const history = useHistory()
  const [stories, setStories] = useState()
  const [loading, setLoading] = useState(false)
  const [watermark, setWatermark] = useState(false)

  const handlerOpenStories = (event) => {
    // console.log(event)
    setWatermark(event.watermark)
    setStories([{ url: event.url, type: event.type, duration: event.duration, header: { heading: event.description } }])
    // setStories([{ url: event.url, type: event.type, duration: event.duration }])
    const body = document.querySelector('body')
    body.style.overflow = 'hidden'
  }

  const handlerCloseStory = (event) => {
    setStories()
    const body = document.querySelector('body')
    body.style.overflow = 'inherit'
  }

  const handlerOpenStoriesByUser = async (feed) => {
    if (feed.is_subscription) {
      setLoading(true)
      const story = await getProducerStories(feed.id)
      const token = sessionStorage.getItem('token')
      let itens = []
      if (story.data.length > 0) {
        const classEffect = document.getElementsByName(`feed-${feed.id}`)
        let i = 0
        while (i < classEffect.length) {
          // console.log(classEffect[i++])
          classEffect[i++].classList.remove('active-effect')
        }
        setWatchedPost(feed.id)
        itens = story.data.map((item) => {
          const url = `${item.cdn_url}?token=${token}&type=media`
          const type = item.cdn_url.endsWith('.mp4') ? 'video' : 'image'
          const duration = '115000'
          return { url, type, duration }
        })
        setStories(itens)
      }
      setLoading(false)
    } else {
      history.push({ pathname: `/${feed.nickname}` })
    }
  }

  // async function getBase64 (url) {
  //   const response = await api.get(url, { responseType: 'arraybuffer' })
  //   const valor = Buffer.from(response.data, 'binary').toString('base64')
  //   // console.log(valor)
  //   return valor
  // }
  return (
    <>
      <Row className={'Feeds px-1 px-sm-2'}>
        {props.items.map((item, index) => (
          <Col key={'feeds-' + index}
            xs={12}
            className={'box overflow-hidden p-1 p-sm-2 mb-4 mt-0'}
          >
            <div className='d-flex align-items-center mb-1'>
              <Avatar
                size={50}
                url={{ id: item.user_id }}
                lastPost={item.latest_stories}
                name={`feed-${item.user_id}`}
                onClick={() => handlerOpenStoriesByUser(item)}
              />
              <Link to={item.nickname} className='ml-2 text-decoration-none'>
                <p className='mb-0 font-weight-bold'>@{item.nickname}</p>
              </Link>
            </div>
            <div className='img w-100 d-flex rounded-lg mb-2' style={{ backgroundImage: `url(${item.thumbnail})` }} onClick={() => handlerOpenStories(item)}>
              <Watermarks active={item.watermark}/>
            </div>
            <p className='font-italic text-muted'>{item.description}</p>
          </Col>
        ))}
      </Row>
      { stories &&
        <Row className={'Gallery'}>
          <div className={'Stories bg-dark text-white text-right'}>
            <nav className="navbar navbar-dark bg-dark">
              <a className="navbar-brand" onClick={handlerCloseStory} style={{ cursor: 'pointer' }}><LogoInline /></a>
              <MdClose size={25} onClick={handlerCloseStory} className={'btn-close m-2'}/>
            </nav>
            { stories[0].type === 'video'
              ? <VideoPlayer source={stories[0]} watermark={watermark} onVideoEnd={handlerCloseStory}/>
              : <>
                <Watermarks active={watermark} />
                <Storie stories={stories} onAllStoriesEnd={handlerCloseStory} width='100vw' height='100%'/>
              </>
            }
          </div>
        </Row>
      }
      <LoadingPage show={loading} />
    </>
  )
}

export const VideoPlayer = ({ source, watermark, onVideoEnd }) => {
  // console.log('VideoPlayer', props.source)
  const [mark, setMark] = useState()
  const [user, setUser] = useState({ name: '' })
  const [isPlay, setIsPlay] = useState(true)
  const [view, setView] = useState({ height: 0, width: 0 })
  const [rate, setRate] = useState({ color: 'white', velocity: 1 })
  const video = useRef()
  const controllerRange = useRef()
  const timeRange = useRef()
  const timeRangeTotal = useRef()
  const timeFaster = useRef()
  const titleFaster = useRef()

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')))
  }, [])

  function startProgress () {
    // console.log('startProgress')
    setIsPlay(false)
    controllerRange.current.value = 0
    const text = `${user.name} - ${user.registry_code} - `
    const canvas = document.createElement('canvas')
    canvas.width = window.screen.width
    canvas.height = window.screen.height
    const ctx = canvas.getContext('2d')
    if (watermark) {
      ctx.globalAlpha = 1
      ctx.font = '10px Arial'
      ctx.fillStyle = '#ffffff20'
      ctx.textAlign = 'center'
      ctx.rotate(30.6)
      for (let index = 0; index < 40; index++) {
        ctx.fillText(text.repeat(17), 0, index * 40)
      }
      setMark(ctx.canvas.toDataURL())
    }
  }

  function stopProgress () {
    // console.log('stopProgress')
    setIsPlay(true)
    onVideoEnd()
  }

  function startPauseProgress () {
    setIsPlay(!isPlay)
    // console.log(isPlay)
    if (isPlay) {
      video.current.play()
    } else {
      video.current.pause()
    }
  }

  function updateProgress (event) {
    // console.log('videoPlayer', event.currentTarget.currentTime)
    controllerRange.current.max = event.currentTarget.duration
    controllerRange.current.value = event.currentTarget.currentTime

    timeRangeTotal.current.innerHTML = formatSecondsInHour(event.currentTarget.duration)
    timeRange.current.innerHTML = formatSecondsInHour(event.currentTarget.currentTime)
  }

  function moveProgress (event) {
    // console.log('moveProgress', event.currentTarget.value)
    video.current.currentTime = event.currentTarget.value
  }

  function fasterProgress (event) {
    // console.log('fasterProgress', event.currentTarget.value)
    if (rate.velocity === 1) {
      setRate({ ...rate, color: 'green', velocity: 2 })
      video.current.playbackRate = 2
      timeFaster.current.innerHTML = '1x'
    } else {
      setRate({ ...rate, color: 'white', velocity: 1 })
      video.current.playbackRate = 1
      timeFaster.current.innerHTML = '2x'
    }
  }

  function loadedMetaDataProgress () {
    // console.log('loadedMetaDataProgress', window.screen.height)
    setView({ height: window.innerHeight - 150, width: 0 })
    if (window.innerHeight > window.innerWidth && video.current.videoWidth > video.current.videoHeight) {
      setView({ height: 'auto', width: 0 })
    }
  }

  function formatSecondsInHour (value) {
    const n = new Date(0, 0)
    n.setSeconds(n.getSeconds() + value)
    return n.toTimeString().slice(3, 8)
  }

  return (
    <div className='vh-100 text-center'>
      <img src={watermarkSafety} onClick={startPauseProgress} style={{ backgroundImage: `url(${mark})`, left: 0, zIndex: 1 }} className='w-100 h-100 border-0 position-absolute'/>
      <video src={source.url}
        type="video/mp4"
        style={{ maxWidth: 100 + '%' }}
        height={view.height}
        ref={video}
        autoPlay={true}
        muted={false}
        playsInline={true}
        webkit-playsinline={true}
        controls={false}
        controlsList='nodownload'
        onLoadStart={startProgress}
        onTimeUpdate={updateProgress}
        onEnded={stopProgress}
        onClick={startPauseProgress}
        onLoadedMetadata={loadedMetaDataProgress} />
      <nav id='videoPlayerController' className='navbar fixed-bottom mb-3'>
        <button onClick={startPauseProgress} className='btn btn-link text-white text-decoration-none p-0 mr-3'>
          {isPlay
            ? <BsPlayFill color='write' size={20}/>
            : <BsPauseCircle color='write' size={20}/>
          }
        </button>
        <span><span ref={timeRange}>00:00</span> / <span ref={timeRangeTotal}>00:00</span></span>
        <button onClick={fasterProgress} ref={timeFaster} className='btn btn-link text-white text-decoration-none ml-auto'>
          2x
        </button>
        <input type="range" ref={controllerRange} min="0" step={0.01} onMouseUp={moveProgress} onTouchEnd={moveProgress} className='w-100 mt-1'/>
      </nav>
    </div>
  )
}
