import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import axios from 'axios'
import './services/api'
import './styles/bootstrap.scss'

// let token = document.head.querySelector('meta[name="csrf-token"]');
axios.defaults.baseURL = process.env.REACT_APP_API_URL

// axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
delete axios.defaults.headers.common.authorization
ReactDOM.render(
  <App />,
  document.getElementById('root')
)
