import api from './api'

/**
 * @param int id Producer ID
 */
export default (id, paginate = 50) => {
  return api
    .get(`/producers/${id}/stories`, { params: { context: 'story', paginate } })
    .then(({ data }) => data)
}
