import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import logoImg from '../../assets/logo/tokplace-logo-inline.svg'
import api from '../../services/api'
import { alert, confirm } from 'react-bootstrap-confirmation'
import { kondutoPage } from '../../services/konduto'

function Invite (query) {
  const history = useHistory()
  let token = ''
  let emailQuery = ''
  if (query.match.params.token !== undefined) {
    token = query.match.params.token
    emailQuery = query.match.params.email
  } else {
    token = localStorage.getItem('tokenInvite')
    emailQuery = localStorage.getItem('emailInvite')
  }
  useEffect(() => {
    kondutoPage('invite')
    autoLogin()
  }, [])

  async function autoLogin () {
    const params = {
      email: emailQuery
    }
    try {
      await api.post(`/invites/${token}/login`, params).then((res) => {
        const status = res.status
        if (status === 201) {
          localStorage.setItem('tokenInvite', token)
          sessionStorage.setItem('token', res.data.token)
          localStorage.setItem('email', res.data.user.email)
          localStorage.setItem('name', res.data.user.name)
          localStorage.setItem('user', JSON.stringify(res.data.user))
          alert('Login efetuado com sucesso', { okButtonStyle: 'success' })
          history.push({ pathname: '/invitesAccept', state: { token: token } })
        }

        if (status === 200) {
          alert('Por favor, efetue seu cadastro para continuar.', { okButtonStyle: 'success' })
          localStorage.setItem('emailInvite', emailQuery)
          localStorage.setItem('tokenInvite', token)
          history.push({ pathname: '/signup', state: { email: emailQuery } })
        }
      })
    } catch (error) {
      alert('Seu convite expirou.', { okButtonStyle: 'danger' })
      history.push('/login')
    }
  }

  return (
    <Container>
      <img src={logoImg} className={'fluid-img'}/>
    </Container>
  )
}

export default Invite
