import api from './api'

/**
 * @param int id Producer ID
 */
export default (url, paginate = 50) => {
  return api
  // .get(`/producers/${id}/stories`, { params: { context: 'gallery', paginate } })
    .get(url, { params: { context: 'gallery', paginate } })
    .then(({ data }) => data)
}
