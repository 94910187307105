import api from './api'

export default (setter) => {
  return api.get('/producers/affiliates').then(({ data }) => {
    if (setter) {
      setter(data)
    }
    return data
  })
}
