import React from 'react'
// import { Form, FormFile, Feedback } from 'react-bootstrap'
// import { FileInput } from '@react-md/form'
import './style.scss'

const ButtonFiles = (props) => {
  return (
    <label className={'UploadFiles ' + props.className}>{props.icon} <span>{props.title}</span>
      <input type="file" name='uploadFiles' context={props.context ? props.context : null} onChange={props.onChange}/>
    </label>
    // <label htmlFor='uploadFiles' className={'UploadFiles ' + props.className}>{props.icon} <span>{props.title}</span>
    //   <input type="file" id='uploadFiles' name='uploadFiles' context={props.context ? props.context : null} onChange={props.onChange}/>
    // </label>
  )
}

export default ButtonFiles
