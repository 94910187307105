import React, { useEffect, useState, useRef } from 'react'
import { useHistory, Switch, NavLink } from 'react-router-dom'
import { Container, Row, Col, Nav, Media, Button, Modal } from 'react-bootstrap'
import { alert, confirm } from 'react-bootstrap-confirmation'
import { RiEditBoxLine } from 'react-icons/ri'
import { BsArrowClockwise } from 'react-icons/bs'
import { FaTrashAlt } from 'react-icons/fa'
import { BiImageAdd } from 'react-icons/bi'
import ButtonFiles from '../../components/ButtonFiles/Index'
import Avatar from '../../components/Avatar'
import Cover from '../../components/Cover'
import { kondutoPage, kondutoCustomerId } from '../../services/konduto'
import api from '../../services/api'
import getProducerInfo from '../../services/getProducerInfo'
// import getProducerStories from '../../services/getProducerStories'
import { getCoverURL, getMediaURL, time_ago } from '../../services/utils.js'
import { LoadingPage } from '../../components/Loadings'
import ButtonLoading from '../../components/ButtonLoading'
import AuthRoutes from '../../routes/privateRoutes'
// import ViewChatVideo from './ViewChatVideo'
import { Stories } from '../../components/Gallery'
// import { Validate, ffile } from '../../utils/FieldsConstrains'
import videoTokplace from '../../assets/videos/new-tokplace.mp4'
import './style.scss'

function Profile () {
  const history = useHistory()
  let upload_stories_message_contador = 0
  const upload_stories_message = [
    'Estamos carregando o seu arquivo. Isso pode demorar alguns minutos. Aguarde.',
    'Seu arquivo já está sendo processado e você pode fazer outras coisas enquanto concluímos o trabalho.',
    'Aguarde mais alguns instantes…',
    'Nossos servidores estão compactando o seu vídeo para uma melhor experiência do seu assinante.',
    'Vídeos com mais de 3 minutos podem levar até 5 minutos para compactar. Já estamos finalizando!'
  ]
  const [user, setUser] = useState()
  // const [storyID, setStoryID] = useState()
  const [stories, setStories] = useState()
  const [publico, setPublico] = useState([])
  const [gallery, setGallery] = useState([])
  const [posts, setPosts] = useState([])
  // const [file, setFile] = useState('')
  const [loadingButton, setLoadingButton] = useState(false)
  const [producerInfo, setProducerInfo] = useState({ subscribers: 0, stats: { posts: 0 } })
  const [producerImgPerfil, setProducerImgPerfil] = useState({ id: null, avatar: null, cover: null })
  const [loading, setLoading] = useState({ load: false, percentage: null, message: null })
  const [watermark, setWatermark] = useState(false)
  // useEffect(() => {
  //   openNewVideoTokplace()
  // }, [])
  useEffect(() => {
    kondutoPage('user')
    getUser()
  }, [])
  const openNewVideoTokplace = () => {
    setTimeout(() => { localStorage.setItem('videoNewTokplace', true) }, 10000)
    if (!localStorage.getItem('videoNewTokplace')) {
      setStories([{ url: videoTokplace, type: 'video' }])
    }
  }

  const Validafile = (files, context) => {
    if (context === 'public') {
      if (gallery.data.length < 10) {
        alert('Para postar fotos públicas, é necessário que você possua no mínimo 10 fotos ou vídeos postados na sua galeria.')
        return false
      }
      if (!(files.type).includes('image')) {
        alert('vídeo não é permitido!')
        return false
      } else if (publico.data.length >= 3) {
        alert('Limite de 3 postagens públicas atingido!')
        return false
      } else {
        return true
      }
    }
    if (files.size > 209715200) {
      alert('Tamanho do vídeo não permitido!')
      return false
    }
    return true
  }

  const handlerPostsAdd = (event) => {
    setLoading({ ...loading, load: true, message: 'Aguarde Upload!' })
    const [files] = Array.from(event.currentTarget.files || [null])
    const context = event.currentTarget.attributes.context.value
    const validaFile = Validafile(files, context)
    if (validaFile) {
      // setFile(files)
      const formData = new FormData()
      formData.append('file', files)
      formData.append('upload_preset', 'xw4yrog1')

      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          if (percent < 100) {
            setLoading({ load: true, percentage: percent, message: 'Aguarde Upload!' })
          } else {
            setLoading({ load: true, percentage: null, message: 'Estamos carregando o seu arquivo. Aguarde!' })
          }
        },
        params: {
          context: context
        }
      }

      api.post('/stories', formData, options).then((res) => {
        // console.log('Res: ', res)
        setProducerInfo({ ...producerInfo, stats: { posts: producerInfo.stats.posts + 1 } })
        alert('O arquivo já está em nossos servidores e está sendo processado. Você poderá fazer outras coisas enquanto concluímos o trabalho.')
        getStories(context)
      }).catch(() => {
        alert('Falha ao salvar seus Stories', { okButtonStyle: 'danger' })
      }).finally(() => setLoading({ ...loading, load: false, percentage: null }))
    } else {
      setLoading({ ...loading, load: false, percentage: null })
    }
    event.preventDefault()
  }

  const handlerLoadStories = (context) => {
    const token = sessionStorage.getItem('token')
    let itens = []
    const hasStories = context === 'posts' ? posts.data.length > 0 : gallery.data.length > 0
    if (hasStories) {
      itens = posts.data.map((item) => {
        const url = `${item.cdn_url}?token=${token}&type=media`
        const type = item.cdn_url.endsWith('.mp4') ? 'video' : 'image'
        const duration = context === 'posts' ? '15000' : '115000' // 15 segundos
        return { url, type, duration, header: { heading: item.description } }
      })
      setStories(itens)
    } else {
      console.log('não existe imagem em ', context)
    }
  }
  const handlerChangeWatermark = (event) => {
    setWatermark(!watermark)
    api.post(`/users/watermark/${user.id}`, { watermark: !watermark }).then(resp => {
      console.log(resp)
      alert('Marca dágua salvo com sucesso', { okButtonStyle: 'success' })
    }).catch(erro => {
      setWatermark(watermark)
      alert('Falha ao salvar Marca dágua', { okButtonStyle: 'danger' })
    })
  }

  const getUser = async () => {
    setLoading({ ...loading, load: true })
    try {
      const { data } = await api.get('/users/me')
      kondutoCustomerId(data.vindi_customer)
      // console.log('data', data)
      localStorage.setItem('user', JSON.stringify(data))
      setWatermark(data.watermark)
      if (data) {
        setUser(data)
        getProducerImgPerfil(data)
        getProducerInfo(data.nickname, setProducerInfo)
        setLoading({ ...loading, load: false })
      }
      if (data.is_producer) {
        getStories('story')
        getStories('gallery')
        getStories('public')
      } else {
        history.push('/mySignatures')
      }
    } catch (error) {
      alert('Falha ao buscar seus dados', { okButtonStyle: 'danger' })
      const code = error.response.status
      if (code === 401) {
        alert('você esta sendo redirecionado ao login', { okButtonStyle: 'success' })
        sessionStorage.removeItem('token')
        localStorage.removeItem('name')
        localStorage.removeItem('email')
        localStorage.removeItem('user')
        history.push('/login')
      }
      setLoading({ ...loading, load: false })
    }
  }
  const getStories = (context) => {
    // _context = 'gallery' ou 'story'
    setLoading({ ...loading, load: true, message: upload_stories_message[upload_stories_message_contador] })
    api.get('/stories', { params: { context: context } }).then((res) => {
      if (res.data.data.length > 0) {
        if (res.data.data[0].stage === 'created') {
          setTimeout(() => {
            upload_stories_message_contador = upload_stories_message_contador < upload_stories_message.length - 1 ? upload_stories_message_contador + 1 : 0
            getStories(context)
          }, 40000)
        } else {
          // console.log(context, res.data)
          if (context === 'gallery') {
            setGallery(res.data)
          } else if (context === 'story') {
            setPosts(res.data)
          } else if (context === 'public') {
            setPublico(res.data)
          }
          upload_stories_message_contador = 0
          setLoading({ ...loading, load: false, message: null })
        }
      } else {
        upload_stories_message_contador = 0
        if (context === 'gallery') {
          setGallery({ data: [] })
        } else if (context === 'story') {
          setPosts({ data: [] })
        } else if (context === 'public') {
          setPublico({ data: [] })
        }
        setLoading({ ...loading, load: false, message: null })
      }
    }).catch((erro) => {
      upload_stories_message_contador = 0
      setLoading({ ...loading, load: false, message: null })
      alert('Falha ao buscar seus arquivos', { okButtonStyle: 'danger' })
    })
  }
  const getStoriesMore = (url, context) => {
    setLoadingButton(true)
    api.get(url.replace(process.env.REACT_APP_API_URL, ''), { params: { context: context } }).then(resp => {
      const new_posts = resp.data
      if (context === 'gallery') {
        new_posts.data = gallery.data.concat(resp.data.data)
        setGallery(new_posts)
      } else if (context === 'story') {
        new_posts.data = posts.data.concat(resp.data.data)
        setPosts(new_posts)
      } else if (context === 'public') {
        new_posts.data = publico.data.concat(resp.data.data)
        setPublico(new_posts)
      }
      setLoadingButton(false)
    })
  }

  const getProducerImgPerfil = (data) => {
    setProducerImgPerfil({
      id: data.id,
      avatar: data.avatar,
      cover: data.cover ? getCoverURL(data) : null
    })
  }
  const getStoryType = (mimeType) => {
    const mimeToType = {
      image: 'imagem',
      video: 'video'
    }
    const parts = mimeType.split('/')
    return mimeToType[parts[0]] || null
  }

  const NavProducerServices = () => {
    return (
      <div className={'NavProducerServices'}>
        <div className="custom-control custom-switch mt-3 mb-0 pl-0">
          <label className="mr-5">Habilitar marca d&apos;agua nos meus posts</label>
          <input type="checkbox" className="custom-control-input" id="watermark" checked={watermark} onChange={handlerChangeWatermark}/>
          <label className="custom-control-label" htmlFor="watermark"></label>
        </div>
        <Nav className="btn-group my-2 mb-sm-5 mx-auto">
          <NavLink exact to='/producer' activeClassName="active" className='btn btn-secondary-light text-primary py-4'>Meus Stories</NavLink>
          <NavLink to='/producer/public' activeClassName="active" className='btn btn-secondary-light text-primary py-4'>Público</NavLink>
          <NavLink to='/producer/galeria' activeClassName="active" className='btn btn-secondary-light text-primary py-4'>Minha Galeria</NavLink>
          {/* <NavLink to='/producer/chat-video' activeClassName="active" className='btn btn-dark py-4'>Meu Chat Vídeo</NavLink> */}
        </Nav>
        <div className="views-servicos mb-5">
          <Switch>
            <AuthRoutes exact path="/producer" component={ViewStories} />
            <AuthRoutes path="/producer/stories" component={ViewStories} />
            <AuthRoutes path="/producer/public" component={ViewPublic} />
            <AuthRoutes path="/producer/galeria" component={ViewGallery} />
            {/* <AuthRoutes path="/producer/chat-video" component={ViewChatVideo} /> */}
          </Switch>
        </div>
      </div>
    )
  }

  const ListStories = ({ item, context }) => {
    const [editing, setEditing] = useState(false)
    const controlStoriesRef = useRef(null)

    async function handlerPostsMoreHours () {
      setLoading({ ...loading, load: true })
      api.post(`/stories/${item.id}/morehours`).then((res) => {
      }).catch(() => {
        alert('Não foi possível adicionar mais tempo, tente mais tarde.', { okButtonStyle: 'danger' })
      }).finally(() => setLoading({ ...loading, load: false }))
    }
    async function handlerPostsDelete () {
      const result = await confirm('Deseja deletar este conteúdo?')
      if (result) {
        setLoading({ ...loading, load: true })
        api.delete(`/stories/${item.id}`).then((res) => {
          if (context === 'story') {
            setPosts({ ...posts, data: filtrarListaStories(posts.data, item.id) })
          }
          if (context === 'public') {
            setPublico({ ...publico, data: filtrarListaStories(publico.data, item.id) })
          }
          if (context === 'gallery') {
            setGallery({ ...gallery, data: filtrarListaStories(gallery.data, item.id) })
          }
          setProducerInfo({ ...producerInfo, stats: { posts: producerInfo.stats.posts - 1 } })
        }).catch(() => {
          alert(`Falha ao excluir seu ${context}`, { okButtonStyle: 'danger' })
        }).finally(() => setLoading({ ...loading, load: false }))
      }
    }
    function filtrarListaStories (itens, id) {
      return itens.filter(item => item.id !== id)
    }
    function addDescriptionListaStories (itens, id, text) {
      return itens.map((item) => item.id === id ? { ...item, description: text } : item)
    }
    function handlerEditDescription () {
      controlStoriesRef.current.classList.add('d-none')
      setEditing(true)
    }
    const EditDescription = ({ text, editing, id, control }) => {
      const inputRef = useRef(null)
      function saveDescription () {
        setEditing(false)
        setLoading({ load: true, percentage: null, message: 'Aguarde' })
        const newText = inputRef.current.value
        api.put(`/stories/${id}/description`, { description: newText }).then((res) => {
          // window.location.reload(false)
          if (context === 'story') {
            setPosts({ ...posts, data: addDescriptionListaStories(posts.data, item.id, newText) })
          }
          if (context === 'public') {
            setPublico({ ...publico, data: addDescriptionListaStories(publico.data, item.id, newText) })
          }
          if (context === 'gallery') {
            setGallery({ ...gallery, data: addDescriptionListaStories(gallery.data, item.id, newText) })
          }
        }).catch().finally(() => setLoading({ load: false }))
      }
      function done () {
        control.current.classList.remove('d-none')
        setEditing(false)
      }
      if (editing) {
        return (
          <div className='form-group'>
            <textarea className="form-control mb-2" rows="3" ref={inputRef}>{text}</textarea>
            <button type="button" className="btn btn-primary mb-2 w-100" onClick={saveDescription}>Salvar</button>
            <button type="button" className="btn btn-danger mb-2 w-100" onClick={done}>Cancelar</button>
          </div>
        )
      }
      return (text !== '' && <p className='mb-1'>{text}</p>)
    }
    return (
      <Media className={'mb-2'}>
        <img
          width={90}
          height={90}
          className="mr-3 gallery"
          src={getMediaURL(item)}
          alt="Generic placeholder"
        />
        <Media.Body className={'border-top pt-0'}>
          <EditDescription text={item.description} id={item.id} editing={editing} control={controlStoriesRef} />
          <p className='text-uppercase mb-0'>{getStoryType(item.mime_type)}</p>
          <p className="mb-0">
            <small>
              {item.status === 'published' ? 'Publicado' : 'Pendente' } {time_ago(item.published_at)}
            </small>
          </p>
        </Media.Body>
        <Media.Body ref={controlStoriesRef} className='text-right m-auto'>
          {context === 'story' &&
            <Button
              className={'mb-2 p-2 text-primary ml-2'}
              variant='secondary-light'
              title='+24h'
              value={item.id}
              onClick={handlerPostsMoreHours}
            >
              <BsArrowClockwise size={24} className='text-primary'/> Repostar
            </Button>
          }
          <Button
            className={'p-2 rounded-circle ml-2 mb-2'}
            variant='secondary-light'
            title='Edit description'
            value={item.id}
            onClick={handlerEditDescription}
          >
            <RiEditBoxLine size={24} className='text-primary'/>
          </Button>
          <Button
            className={'p-2 rounded-circle ml-2 mb-2'}
            variant='secondary-light'
            // id="button-delete"
            value={item.id}
            onClick={handlerPostsDelete}
          >
            <FaTrashAlt size={24} className='text-primary'/>
          </Button>
        </Media.Body>
      </Media>
    )
  }

  const ViewStories = () => {
    return (
      <Row className="views-servicos-stories">
        <Col md={8}>
          <h3>Meus Stories</h3>
          <p className="text-muted">Fotos e vídeos que ficam no ar por apenas 24 horas para seus assinantes</p>
        </Col>
        <Col md={4} className="text-right mb-3">
          <ButtonFiles onChange={ handlerPostsAdd } className={'btn btn-light border border-secondary-light'} context='story' title={'POSTAR'} icon={<BiImageAdd size={25}/>}/>
        </Col>
        <Col>
          { posts.data &&
            posts.data.map((item) => (
              <ListStories key={`list-story-${item.id}`} item={item} context='story'/>
            ))
          }
          { posts.next_page_url &&
            <Button
              className={'mt-3 col-12 rounded-pill'}
              variant='btn btn-primary'
              onClick={() => { getStoriesMore(posts.next_page_url, 'story') }}
            >
              <ButtonLoading label={'Carregar mais'} active={loadingButton} />
            </Button>
          }
        </Col>
      </Row>
    )
  }
  const ViewPublic = () => {
    // console.log('user', !user.isPublicStoriesBlocked)
    return (
      <Row className="views-servicos-stories">
        {
          !user.is_publicStoriesBlocked === true &&
          <>
            <Col md={8}>
              <h3>Público</h3>
              <p className="lead">Temos uma novidade pra você!</p>
              <p className="text-muted">Agora você poderá postar até 03 fotos públicas que aparecerão no feed de todos os usuários da TokPlace!</p>
              <p className="text-muted">Aproveite essa nova funcionalidade para divulgar seu perfil, mas lembre-se: <strong>Não são permitidas fotos com nudez.</strong></p>
            </Col>
            <Col md={4} className="text-right mb-3">
              <ButtonFiles onChange={ handlerPostsAdd } className={'btn btn-light border border-secondary-light'} context='public' title={'POSTAR'} icon={<BiImageAdd size={25}/>}/>
            </Col>
            <Col>
              { publico.data &&
                publico.data.map((item) => (
                  <ListStories key={`list-public-${item.id}`} item={item} context='public'/>
                ))
              }
              { publico.next_page_url &&
                <Button
                  className={'mt-3 col-12 rounded-pill'}
                  variant='btn btn-primary'
                  onClick={() => { getStoriesMore(publico.next_page_url, 'public') }}
                >
                  <ButtonLoading label={'Carregar mais'} active={loadingButton} />
                </Button>
              }
            </Col>
          </>
        }
        {
          !user.is_publicStoriesBlocked === false &&
          <Col>
            <h4>Atenção!</h4>
            <p>Você violou a política de uso para publicação de imagens públicas e foi temporariamente bloqueado para novas postagens nesta área.</p>
            <p>Em caso de dúvidas entre em contato com <a href='mailto:sac@tokplace.com'>sac@tokplace.com</a></p>
          </Col>
        }
      </Row>
    )
  }
  const ViewGallery = () => {
    return (
      <Row className="views-servicos-stories">
        <Col md={8}>
          <h3>Minha Galeria</h3>
          <p className="text-muted">Fotos e vídeos que ficam disponíveis para seus assinantes na galeria da sua pagina de perfil</p>
        </Col>
        <Col md={4} className="text-right mb-3">
          <ButtonFiles onChange={ handlerPostsAdd } className={'btn btn-light border border-secondary-light'} context='gallery' title={'POSTAR'} icon={<BiImageAdd size={25}/>}/>
        </Col>
        <Col>
          { gallery.data &&
            gallery.data.map((item) => (
              <ListStories key={`list-gallery-${item.id}`} item={item} context='gallery'/>
            ))
          }
          { gallery.next_page_url &&
            <Button
              className={'mt-3 col-12 rounded-pill'}
              variant='btn btn-primary'
              onClick={() => { getStoriesMore(gallery.next_page_url, 'gallery') }}
            >
              <ButtonLoading label={'Carregar mais'} active={loadingButton} />
            </Button>
          }
        </Col>
      </Row>
    )
  }

  return (
    <section>
      <Container fluid='md' className={'Profile bg-light'}>
        {user && <div className='row'><Cover url={ producerImgPerfil.cover }/></div>}
        <Row className={'px-md-5'}>
          {user &&
          <Col md={6} style={{ marginTop: -45 + 'px' }}>
            <Avatar url={ producerImgPerfil.avatar === null ? null : producerImgPerfil } size={90} onClick={() => handlerLoadStories('posts')}/>
            <ul className="list-unstyled font-weight-light mt-3">
              <li className={'font-weight-bold'}>{ user && user.name }</li>
              <li>{ user && user.bio ? user.bio : 'Você ainda não tem uma biografia'}</li>
              <li><a href={`/${user && user.nickname}`} className={'text-decoration-none text-lowercase'}>tokplace.com/{ user && user.nickname }</a></li>
            </ul>
          </Col>
          }
          {user &&
          <Col md={6}>
            <ul className="list-inline text-right mt-md-4">
              <li className="list-inline-item mr-4">{ producerInfo.subscribers } Assinantes</li>
              <li className="list-inline-item">{ producerInfo.stats.posts } Postagens</li>
            </ul>
          </Col>
          }
          {user &&
          <Col>
            <NavProducerServices />
          </Col>
          }
        </Row>
        <Stories stories={stories} setStories={setStories}/>
        <LoadingPage
          show={loading.load}
          percent={loading.percentage}
          label={loading.message}
        />
      </Container>
    </section>
  )
}

export default Profile
