import axios from 'axios'

export const locationFromPostalCode = async (postalCode) => {
  const axiosClient = axios.create()
  delete axios.defaults.headers.common.authorization

  try {
    const { data } = await axiosClient.get(`https://viacep.com.br/ws/${postalCode}/json/`)

    if ('erro' in data) {
      throw new Error('CEP não encontrado')
    }

    return {
      postalCode: data.cep,
      city: data.localidade,
      state: data.uf,
      address: data.logradouro,
      district: data.bairro,
      complement: data.complemento
    }
  } catch (e) {
    throw new Error(e)
  }
}
