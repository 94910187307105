import React, { useState } from 'react'
import { Container, Button, Form, Card, InputGroup, FormControl } from 'react-bootstrap'
import { alert, confirm } from 'react-bootstrap-confirmation'
import ButtonLoading from '../../components/ButtonLoading'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { Link, useHistory } from 'react-router-dom'
import { MdChevronLeft } from 'react-icons/md'
import logoImg from '../../assets/logo/tokplace_logo.svg'
import api from '../../services/api'
import { Validate, fpassword } from '../../utils/FieldsConstrains.js'
import '../../styles/forms.scss'

function ChangedPassword (query) {
  const history = useHistory()
  const initialValues = {
    password: '',
    passwordconfirm: ''
  }
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState(initialValues)
  const [validated, setValidated] = useState(false)
  const [btnloading, setBtnloading] = useState(false)
  const [eyes, setEyes] = useState('password')

  const querys = new URLSearchParams(query.location.search)
  const token = querys.get('token')
  const email = querys.get('email')

  function handlePasswordEye (event) {
    const openeye = document.querySelector('#password').attributes.type.value
    if (openeye === 'password') {
      setEyes('text')
    } else {
      setEyes('password')
    }
  }

  const handlerChange = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const { name, msg, vformated } = Validate(event)
    setValues({ ...values, [name]: vformated })
    setErrors({ ...errors, [name]: msg })

    setValidated(true)
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const { msgErros, isValidity } = Validate(event)
    setErrors(msgErros)

    if (isValidity) {
      sendPassword()
    }

    setValidated(true)
  }
  async function sendPassword () {
    setBtnloading(true)
    const param = {
      password: values.password,
      password_confirmation: values.passwordconfirm,
      email: email,
      token: token
    }
    try {
      await api.post('/update-password', param).then(res => {
        history.push('/login')
        alert('Senha Atualizada com sucesso', { okButtonStyle: 'success' })
        setBtnloading(false)
      })
    } catch (error) {
      alert('Falha ao processar o pedido', { okButtonStyle: 'danger' })
      setBtnloading(false)
    }
  }

  return (
    <Container className={'form'}>
      <Card className={'col-md-7 col-lg-5 mx-auto pt-3'}>
        <Link to="/login">
          <MdChevronLeft size={40} color="#000" />
        </Link>
        <Card.Img variant="top" src={logoImg} className={'mx-auto'} alt="tokplace" />
        <Card.Body>
          <Card.Title>Redefinir Senha</Card.Title>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label className={'text-muted'}>Nova Senha</Form.Label>
              <InputGroup hasValidation>
                <FormControl
                  {...fpassword.fields}
                  type={eyes}
                  value={values.password}
                  onChange={handlerChange}
                />
                <InputGroup.Append>
                  <InputGroup.Text
                    className={'view-password border-left-0 bg-transparent'}
                    onClick={handlePasswordEye}
                  >
                    { eyes === 'password' ? <AiFillEye/> : <AiFillEyeInvisible/> }
                  </InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type={'invalid'}> {errors.password} </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label className={'text-muted'}>Confirmar a Nova Senha</Form.Label>
              <InputGroup hasValidation>
                <FormControl
                  {...fpassword.fieldsconfirm}
                  type={eyes}
                  value={values.passwordconfirm}
                  onChange={handlerChange}
                />
                <InputGroup.Append>
                  <InputGroup.Text
                    className={'view-password border-left-0 bg-transparent'}
                    onClick={handlePasswordEye}
                  >
                    { eyes === 'password' ? <AiFillEye/> : <AiFillEyeInvisible/> }
                  </InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type={'invalid'}> {errors.passwordconfirm} </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Button type={'submit'} className={'w-100'} variant="submit" disabled={btnloading}>
              <ButtonLoading label='Salvar' active={btnloading} />
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default ChangedPassword
