import React, { useState, useEffect } from 'react'
import { Container, Button, Form, Card, InputGroup, FormControl, Col, Row } from 'react-bootstrap'
import { Validate, femailguest, fpassword } from '../../utils/FieldsConstrains.js'
import { alert, confirm } from 'react-bootstrap-confirmation'
import { Link, useHistory } from 'react-router-dom'
import logoImg from '../../assets/logo/tokplace_logo.svg'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import api from '../../services/api'
import ButtonLoading from '../../components/ButtonLoading'
import Avatar from '../../components/Avatar'
import { kondutoPage } from '../../services/konduto'
import '../../styles/forms.scss'

function Login () {
  const history = useHistory()
  const initialValues = {
    email: '', password: ''
  }
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState(initialValues)
  const [validated, setValidated] = useState(false)
  const [eyes, setEyes] = useState('password')
  const [btnloading, setBtnloading] = useState(false)
  const [payment, setPayment] = useState()

  useEffect(() => {
    kondutoPage('login')
    pagamento.then((res) => {
      setPayment(res)
    })
  }, [])

  const pagamento = new Promise((resolve, reject) => {
    try {
      resolve(
        JSON.parse(localStorage.getItem('payment'))
      )
    } catch (error) {
      reject(error)
    }
  })

  function handlePasswordEye (event) {
    const openeye = document.querySelector('#password').attributes.type.value
    if (openeye === 'password') {
      setEyes('text')
    } else {
      setEyes('password')
    }
  }
  function handlerCancelPayment () {
    localStorage.removeItem('payment')
    window.location.reload()
  }

  const PaymentMensage = () => {
    const nickname = payment.producer.nickname
    const producer = payment.producer
    return (
      <div className="Payment-login media px-3">
        <Avatar url={producer} size={90} className={'mr-3 float-left'}/>
        <div className="media-body">
          <p className='font-weight-light'>Falta muito pouco para começar a curtir o conteúdo de {nickname}!</p>
          <p className='font-weight-light'>Para concluir a compra, você precisa se logar na TokPlace. Caso ainda não tenha uma conta conosco, <a href='/signup'>cadastre-se</a> agora mesmo.</p>
          <p className='font-weight-light'><a href='#' onClick={handlerCancelPayment}>Cancelar</a> intenção de compra.</p>
        </div>
      </div>
    )
  }
  const handlerChange = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const { name, msg, vformated } = Validate(event)
    setValues({ ...values, [name]: vformated })
    setErrors({ ...errors, [name]: msg })

    setValidated(true)
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const { msgErros, isValidity } = Validate(event)
    setErrors(msgErros)

    if (isValidity) {
      startLogon()
    }

    setValidated(true)
  }

  async function startLogon (data) {
    const parameters = {
      email: values.email,
      password: values.password
    }
    setBtnloading(true)
    try {
      await api.post('/login', parameters).then(res => {
        sessionStorage.setItem('token', res.data.token)
        localStorage.setItem('email', res.data.user.email)
        localStorage.setItem('name', res.data.user.name)
        localStorage.setItem('user', JSON.stringify(res.data.user))
        setBtnloading(false)
        if (payment) {
          history.push('/payment')
          window.location.reload()
        } else {
          history.push('/')
          window.location.reload()
        }
        alert('Login efetuado com sucesso', { okButtonStyle: 'success' })
      })
    } catch (error) {
      if (error.response.status === 403) {
        alert('Seu acesso encontra-se bloquedo. Para mais informações, entre em contato pelo e-mail sac@tokplace.com.', { okButtonStyle: 'danger' })
      } else {
        alert('Por favor, informe e-mail e senha corretos', { okButtonStyle: 'danger' })
      }
      setBtnloading(false)
    }
  }

  return (
    <Container className={'form'}>
      <Card className={'col-md-7 col-lg-5 mx-auto pt-3'}>
        <Card.Img variant="top" src={logoImg} className={'mx-auto'} alt="tokplace" />
        {payment && <PaymentMensage />}
        <Card.Body>
          <Card.Title>
            Login
          </Card.Title>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label className={'text-muted'}>E-mail</Form.Label>
              <Form.Control
                {...femailguest.fields}
                defaultValue={values.email}
                onChange={handlerChange}
              />
              <Form.Control.Feedback type={'invalid'}>{errors.email}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label className={'text-muted'}>Senha</Form.Label>
              <InputGroup hasValidation>
                <FormControl
                  {...fpassword.fields}
                  type={eyes}
                  defaultValue={values.password}
                  onChange={handlerChange}
                />
                <InputGroup.Append>
                  <InputGroup.Text
                    className={'view-password border-left-0 bg-transparent'}
                    onClick={handlePasswordEye}
                  >
                    { eyes === 'password' ? <AiFillEye/> : <AiFillEyeInvisible/> }
                  </InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type={'invalid'}> {errors.password} </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Button type={'submit'} className={'w-100'} variant="submit" disabled={btnloading}>
              <ButtonLoading label='Entrar' active={btnloading} />
            </Button>
          </Form>
          <Form.Group>
            <Form.Label className={'w-100 mb-0'}>Não possui uma conta?</Form.Label>
            <Link to="/signup" className="text-primary text-decoration-none">
              Cadastre-se
            </Link>
          </Form.Group>
          <Form.Group>
            <Form.Label className={'w-100 mb-0'}>Esqueceu sua senha?</Form.Label>
            <Link to="/forgot" className="text-primary text-decoration-none">
              Recuperar senha
            </Link>
          </Form.Group>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default Login
