import React from 'react'
import brand from '../../assets/brand/brand.png'

const PaymentBrands = () => {
  return (
    <img src={brand} className={'w-100'}/>
  )
}

export default PaymentBrands
