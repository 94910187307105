import React from 'react'
import LogoSymbol from '../../assets/logo/tokplace_symbol.svg'

const Cover = (props) => {
  const url = props.url !== null ? `${props.url}?time=${Math.random()}` : LogoSymbol
  const view = props.url !== null ? 'w' : 'h'
  const alt = props.alt || 'Tokplace'
  // style={{ height: 240 + 'px' }}
  return (
    <div className={`d-flex w-100 align-items-center justify-content-center text-center overflow-hidden ${props.className}`} style={{ height: 240 + 'px' }}>
      <img src={url} className={'h-100 p-0'} alt={alt} style={{ width: 'auto' }}/>
    </div>
  )
}

export default Cover
